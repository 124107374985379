import React from "react";
import { Field, ErrorMessage } from "formik";
import { Label, Col, Row } from "reactstrap"; // or whatever library you are using for Col
import Select from "react-select";
class SingleSelectWithOther extends React.Component {
  render() {
    const {
      name,
      label,
      options,
      values,
      setFieldValue,
      errors,
      touched,
      value,
      otherLabel,
      
    } = this.props;

    return (
      <React.Fragment>
        <div>
          <div>
            <Label className="form-label">
              {label}
              {!name === 'referralName' && <span className="text-danger">*</span>}
            </Label>
            <Select
              id={name}
              name={name}
              options={options}
              value={options.find(option => option.value === value)}
              onChange={selectedOption => {
                setFieldValue(name, selectedOption.value);
              }}
              className={`${errors[name] && touched[name] ? "is-invalid" : ""}`}
              placeholder="Select an Option"
            />
            <ErrorMessage
              name={name}
              component="div"
              className="invalid-feedback"
            />
          </div>
          {value === "Other" && (
            <div className="mt-3">
              <Label className="form-label">
                {otherLabel}
                <span className="text-danger">*</span>
              </Label>
              <Field
                type="text"
                id={`${name}OtherText`}
                name={`${name}OtherText`}
                onChange={e => {
                  setFieldValue(`${name}OtherText`, e.target.value);
                }}
                value={values[`${name}OtherText`]}
                className={`form-control ${
                  errors[`${name}OtherText`] && touched[`${name}OtherText`]
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Enter other Option"
              />
              <ErrorMessage
                name={`${name}OtherText`}
                component="div"
                className="invalid-feedback"
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default SingleSelectWithOther;
