import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { setShowPageLoader } from "store/actions";
import { connect } from "react-redux";

import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import BasicNeeds from "./BasicNeeds";
import Intake from "./Intake";
import Education from "./Education";
import Employement from "./Employement";
import BankDetails from "./BankDetails";
import Housing from "./Housing";
import Referrals from "./Referrals";
import { axiosGet } from "helpers/api_helpers";
import HousingAgreementForm from "./HousingAgreement";
import TransitionalHousingForm from "./HousingRules";
import IntakeHealthInfo from "pages/forms/IntakeHealthInfo";
import MedicalInformation from "./MedicalInformation";
import { capitalizeFirstLetter } from "constants/constants";

class ShelterForm extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      open: "8",
      clientData: {},
    };
  }

  componentDidMount = () => {
    this.handleGetClientData();
  };

  toggle = id => {
    if (this.state.open === id) {
      this.setState({ open: "" });
    } else {
      this.setState({ open: id });
    }
  };

  handleGetClientData = async () => {
    const { match, showPageLoader } = this.props;
    const clientId = match?.params?.clientId;

    try {
      showPageLoader(true);
      if (clientId) {
        const response = await axiosGet(`/add-client/screening/${clientId}`);
        if (response?.status) {
          const getClientData = response?.data;
          this.setState({ clientData: getClientData }, () => {});
        }
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      console.error("handleGetScreeningData API error", error);
    }
  };

  render() {
    //meta title
    document.title = "Form | Shelter";

    const { clientData } = this.state;
    const clientName = `${capitalizeFirstLetter(
      clientData?.screeningForm?.generalInformation?.firstName
    )} ${capitalizeFirstLetter(
      clientData?.screeningForm?.generalInformation?.middleName
    )} ${capitalizeFirstLetter(
      clientData?.screeningForm?.generalInformation?.lastName
    )}`;
    const clientId = this.props.match?.params?.clientId;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Form" breadcrumbItem="Shelter" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between mb-3">
                      <h5>{clientName}</h5>
                      <Link to="/department/shelter">
                        <button className="btn btn-secondary">Back</button>
                      </Link>
                    </div>
                    {clientId && Object.values(clientData).length && (
                      <div>
                        <Accordion
                          flush
                          open={[this.state.open]}
                          toggle={this.toggle}
                        >
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="8">
                              Housing Agreement
                            </AccordionHeader>
                            <AccordionBody accordionId="8">
                              <HousingAgreementForm
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                clientId={clientId}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="9">
                              Housing Rules
                            </AccordionHeader>
                            <AccordionBody accordionId="9">
                              <TransitionalHousingForm
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                                clientId={clientId}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="10">
                              Medical Information
                            </AccordionHeader>
                            <AccordionBody accordionId="10">
                              <MedicalInformation
                                clientData={
                                  clientData?.screeningForm?.generalInformation
                                }
                                referenceNote={clientData?.referenceNotesId}
                                intakeData={
                                  clientData?.intakeForm?.healthInformation
                                }
                                shelterData={
                                  clientData?.shelter?.medicalInformation
                                }
                                handleGetClient={this.handleGetClientData}
                                clientId={clientId}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="1">
                              Intake
                            </AccordionHeader>
                            <AccordionBody accordionId="1">
                              <Intake
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="2">
                              Basic Needs
                            </AccordionHeader>
                            <AccordionBody accordionId="2">
                              <BasicNeeds
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="3">
                              Education
                            </AccordionHeader>
                            <AccordionBody accordionId="3">
                              <Education
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="4">
                              Employment
                            </AccordionHeader>
                            <AccordionBody accordionId="4">
                              <Employement
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="5">
                              Bank Account
                            </AccordionHeader>
                            <AccordionBody accordionId="5">
                              <BankDetails
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="6">
                              Housing
                            </AccordionHeader>
                            <AccordionBody accordionId="6">
                              <Housing
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="7">
                              Refferals
                            </AccordionHeader>
                            <AccordionBody accordionId="7">
                              <Referrals
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

const mapStateToProps = ({ user }) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShelterForm));
