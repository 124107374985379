import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";
import { formKey, orientationSubFormKey } from "constants/constants";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import CustomDatePicker from "components/Common/DateField";

const validationSchema = Yup.object().shape({
  workshop: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Required"),
      date: Yup.date().required("Required"),
    })
  ),
});

class Workshops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        workshop: [{ name: "", date: "" }],
        referrenceNote: "",
      },
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;
    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.ORIENTATION &&
          note.subForm === orientationSubFormKey.WORKSHOPS &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }

    this.setState({
      initialValues: {
        workshop: clientData?.orientation?.workshops?.workshop.map(
          workshop => ({
            name: workshop.workshopName,
            date: new Date(workshop.workshopDate).toISOString().split("T")[0],
          })
        ) || [{ name: "", date: "" }],
        referrenceNote: refNote || "",
      },
    });
  };

  handleAddOrientationWorkshopData = async workshopInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/orientation/workshops/${clientData?.id}`,
        workshopInfo
      );
      if (response?.status) {
        toast.success(
          response?.message || "Workshop information stored successfully!"
        );
        handleGetClient();
        toggle("2");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      workshopInfo: {
        workshop: values?.workshop.map(workshopData => ({
          workshopName: workshopData.name,
          workshopDate: workshopData.date,
        })),
      },
      referenceNotes: values.referrenceNote,
    };
    this.handleAddOrientationWorkshopData(data);
  };

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              setFieldTouched,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Workshops</h4>
                        </Col>
                        <Col md="9">
                          <FieldArray name="workshop">
                            {({ insert, remove, push }) => (
                              <div>
                                {values?.workshop?.length > 0 &&
                                  values?.workshop?.map(
                                    (workshopData, index) => (
                                      <div key={index} className="d-flex">
                                        <div className="col-md-4 mb-3 me-3">
                                          <label
                                            htmlFor={`workshop.${index}.name`}
                                            className="form-label"
                                          >
                                            Name
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <Field
                                            id={`workshop.${index}.name`}
                                            name={`workshop.${index}.name`}
                                            type="text"
                                            className={
                                              "form-control" +
                                              (errors.workshop &&
                                              errors.workshop[index] &&
                                              errors.workshop[index].name &&
                                              touched.workshop &&
                                              touched.workshop[index] &&
                                              touched.workshop[index].name
                                                ? " is-invalid"
                                                : "")
                                            }
                                            placeholder="Enter workshop name"
                                          />
                                          <ErrorMessage
                                            name={`workshop.${index}.name`}
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>

                                        <div className="col-md-4 mb-3">
                                          <label
                                            htmlFor={`workshop.${index}.date`}
                                            className="form-label"
                                          >
                                            Date
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          {/* <Field
                                          id={`workshop.${index}.date`}
                                          name={`workshop.${index}.date`}
                                          type="date"
                                          className={
                                            "form-control" +
                                            (errors.workshop &&
                                            errors.workshop[index] &&
                                            errors.workshop[index].date &&
                                            touched.workshop &&
                                            touched.workshop[index] &&
                                            touched.workshop[index].date
                                              ? " is-invalid"
                                              : "")
                                          }
                                        /> */}
                                          <CustomDatePicker
                                            className={
                                              "form-control" +
                                              (errors?.workshop?.[index]
                                                ?.date &&
                                              touched?.workshop?.[index]?.date
                                                ? " is-invalid"
                                                : "")
                                            }
                                            selected={
                                              values?.workshop?.[index]?.date
                                                ? values.workshop?.[index]?.date
                                                : null
                                            }
                                            onChange={date => {
                                              if (date) {
                                                setFieldValue(
                                                  `workshop.${index}.date`,
                                                  date
                                                );
                                              } else {
                                                setFieldValue(
                                                  `workshop.${index}.date`,
                                                  null
                                                );
                                              }
                                            }}
                                            placeholderText="YYYY-MM-DD"
                                          />
                                          <ErrorMessage
                                            name={`workshop.${index}.date`}
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                        <div className="d-flex align-items-center ms-3 mt-2">
                                          <button
                                            type="button"
                                            className="btn btn-danger p-1"
                                            onClick={() => remove(index)}
                                          >
                                            <i className="fs-5 bx bx-x"></i>
                                          </button>
                                        </div>
                                      </div>
                                    )
                                  )}
                                <button
                                  type="button"
                                  className="btn common-button-color"
                                  onClick={() => push({ name: "", date: "" })}
                                >
                                  Add Workshops
                                </button>
                              </div>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="9">
                          <div className="mb-3">
                            <Label for="referrenceNote" className="form-label">
                              Referrence Note
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="referrenceNote"
                              name="referrenceNote"
                              as="textarea"
                              style={{ resize: "none" }}
                              className={
                                "form-control" +
                                (errors?.referrenceNote &&
                                touched?.referrenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              cols={100}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="referrenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn common-button-color" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Workshops));
