import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button, Label } from "reactstrap";
import Pagination from "components/pagination/Pagination";
import Limit from "components/pagination/Limit";

import BootstrapTable from "react-bootstrap-table-next";

import emptyContacts from "../../../assets/images/emptyContact.png";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import DeleteModal from "../../../components/Common/DeleteModal";

import { axiosDel, axiosGet, axiosPatch } from "helpers/api_helpers";
import {
  capitalizeFirstLetter,
  PAGE,
  LIMIT,
  SIZE_PER_PAGE,
  TOTAL_SIZE,
  departments,
  userRoles,
} from "constants/constants";
import { toast } from "react-toastify";
import UserForm from "./Modal/UserForm";

// import { setLoader } from "../../../store/actions";
import { UserContext } from "../../../components/UserProvider/UserProvider";
import { FormControl, InputGroup } from "react-bootstrap";
import { setShowPageLoader } from "store/actions";
import { withTranslation } from "react-i18next";
import ReactSwitch from "react-switch";

class User extends Component {
  commonToast = React.createRef();
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      users: [],
      user: "",
      departments: [],
      showUserModal: false,
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      page: PAGE,
      searchTerm: "",
      deleteModal: false,
      gender: "male",
      contactListColumns: [
        {
          text: "User",
          dataField: "name",
          sort: true,
        },

        {
          text: "Email",
          dataField: "email",
          sort: true,
          formatter: cellContent => {
            return <span>{cellContent || "-"}</span>;
          },
        },
        {
          dataField: "phoneNumber",
          text: "Phone",
          sort: true,
          formatter: cellContent => {
            return <span>{cellContent || "-"}</span>;
          },
        },
        {
          dataField: "role",
          text: "Role",
          sort: true,
          formatter: cellContent => {
            return <span>{capitalizeFirstLetter(cellContent) || "-"}</span>;
          },
        },
        {
          dataField: "departments",
          text: "Departments",
          formatter: (cellContent, user) => {
            return (
              <span>
                {user?.departmentList.map((dept, index) => (
                  <span key={index}>
                    {dept.name}
                    {index < user?.departmentList.length - 1 && ", "}
                  </span>
                ))}
              </span>
            );
          },
        },
        {
          dataField:"isActivated",
          text: "Activate Action",
          formatter: (cellContent, user) => (
            <ReactSwitch
              onChange={() => this.handleActivationToggle(user)}
              checked={user.isActivated}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#34c38f"
              offColor="#ec4561"
              className="me-1 mb-sm-8 mb-2"
            />
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-account-edit-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="edit"
                  onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-trash-can-outline font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="delete"
                  onClick={() => this.onClickDelete(user)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
  }
// Add this method to your component class
handleActivationToggle = async (user) => {
  const { showPageLoader } = this.props;
  const updatedStatus = !user.isActivated;
  try {
    showPageLoader(true);
    const response = await axiosPatch(`/users/activate-status/${user?._id}`, {
      isActivated: updatedStatus,
    });
    if (response?.status) {
      this.setState(prevState => ({
        users: prevState.users.map(u =>
          u._id === user._id ? { ...u, isActivated: updatedStatus } : u
        ),
      }));
      toast.success(response?.message || "User status updated successfully!", {
        position: "top-right",
      });
    } else {
      toast.error(response?.message || "Oops! something went wrong");
    }
    showPageLoader(false);
  } catch (error) {
    showPageLoader(false);
    console.error("error while updating user status :", error);
    toast.error("Error updating user status.");
  }
};

  componentDidMount = () => {
    this.getAllUsers();
    this.getDepartments();
  };
  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.getAllUsers();
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.getAllUsers();
    });
  };

  getAllUsers = async () => {
    const { size, page, searchTerm } = this.state;
    const { showPageLoader } = this.props;
    try {
      showPageLoader(true);
      const users = await axiosGet(
        `users?page=${page}&limit=${size}&searchString=${searchTerm}`
      );
      if (users?.status) {
        this.setState({
          users: users?.data?.results,
          totalSize: users?.data?.totalResults,
        });
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      console.error("error while getting all users :", error);
    }
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.getAllUsers();
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchTerm: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  deleteUser = async () => {
    const { showPageLoader } = this.props;
    try {
      showPageLoader(true);
      const response = await axiosDel(`/users/${this.state.user?._id}`);
      if (response?.status) {
        this.getAllUsers();
        this.setState({ deleteModal: false });
        toast.success(response?.message || "user deleted successfully!", {
          position: "top-right",
        });
      } else {
        toast.error(response?.message || "Oops! something went wrong");
        this.setState({ deleteModal: false });
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      console.error("error while adding user :", error);
    }
  };

  toggle = () => {
    this.setState(prevState => ({
      showUserModal: !prevState.showUserModal,
    }));
  };

  onClickDelete = users => {
    this.setState({ user: users });
    this.setState({ deleteModal: true });
  };

  handleUserClicks = () => {
    this.setState({ user: "", isEdit: false });
    this.toggle();
  };

  getDepartments = async () => {
    try {
      const response = await axiosGet("/department");
      if (response.status) {
        this.setState({ departments: response.data.results });
      }
    } catch (error) {
      console.error("error at getDepartments :", error);
    }
  };

  handleUserClick = arg => {
    const user = arg;
    const { departments } = this.state;
    const selectedDepartments = user?.departments
      ?.map(departmentId => {
        // Find the department object with matching ID
        const department = departments.find(dept => dept.id === departmentId);

        // If department exists, create the object with label as name and value as ID
        if (department) {
          return {
            label: capitalizeFirstLetter(department.name), // Assuming capitalizeFirstLetter is defined
            value: departmentId,
          };
        }

        // If department is not found, return null
        return null;
      })
      .filter(Boolean); // Filter out any null values

    this.setState({
      user: {
        id: user._id,
        name: user.name,
        phoneNumber: user.phoneNumber,
        email: user.email,
        uploadPermission: user.uploadPermission,
        status: user.status,
        gender: user.gender,
        role: user.role,
        departments: selectedDepartments,
      },
      isEdit: true,
    });

    this.toggle();
  };

  handleGenderChange = event => {
    this.setState({ gender: event.target.value });
  };
  render() {
    //meta title
    document.title = "User List";

    const { isEdit, deleteModal, users, user, showUserModal, departments } =
    this.state;

    const defaultSorted = [
      {
        dataField: "createdAt", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    return (
      <React.Fragment>
        {/* <BuyNumber /> */}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.deleteUser}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        {showUserModal ? (
          <UserForm
            showUserModal={showUserModal}
            toggle={this.toggle}
            user={user}
            getAllUsers={this.getAllUsers}
            isEdit={isEdit}
            departments={departments}
          />
        ) : (
          ""
        )}
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Users" breadcrumbItem="User List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <React.Fragment>
                      <Row className="mb-2 d-flex align-items-center justify-content-between">
                        <Col sm="3">
                          <div className="app-search d-none d-lg-block">
                            <div className="position-relative">
                              <FormControl
                                className="search-form-control"
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                value={this.state.searchTerm}
                                onChange={e => {
                                  this.searchUsers(e);
                                }}
                                onKeyDown={e => {
                                  if (e.key === "Enter") {
                                    this.searchUsers(e);
                                  }
                                }}
                              />
                              <span className="search-icon bx bx-search-alt" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="8">
                          <div className="text-sm-end">
                            <Button
                              // color="primary"
                              className="font-16 btn-block common-button-color"
                              onClick={this.handleUserClicks}
                            >
                              <i className="mdi mdi-plus-circle-outline me-1" />
                              Add New User
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="d-flex justify-content-center">
                            <img
                              src={emptyContacts}
                              alt="empty-conatct"
                              className={`${
                                this.state.users.length === 0
                                  ? ""
                                  : "display-none"
                              }`}
                            ></img>
                          </div>
                          <div
                            className={`table-responsive ${
                              this.state.users.length === 0
                                ? "display-none"
                                : ""
                            }`}
                          >
                            <BootstrapTable
                              keyField="_id"
                              data={users}
                              columns={this.state.contactListColumns}
                              defaultSorted={defaultSorted}
                              classes={"table align-middle table-nowrap"}
                              bordered={false}
                              striped={false}
                              responsive
                              ref={this.node}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="mb-2">
                          <div className="d-flex justify-content-between align-items-center mb-3 w-100">
                            <Limit
                              value={this.state.size}
                              onChange={this.handleLimitChange}
                            />
                            <Pagination
                              totalSize={this.state.totalSize}
                              handlePageClick={this.handlePageClick}
                              currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                              currentSize={this.state.size} // Use selectedLimit for current size
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

const mapStateToProps = state => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(User));

// export default User;
