import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import { Label } from "reactstrap";
import { axiosPatch } from "helpers/api_helpers";

class RefComponent extends Component {

  formikRef = null;

  submitForm = async () => {
    if (this.formikRef) {
      await this.formikRef.submitForm();
    }
  };
  handleFormChange = (values) => {
    if(this.props.onChange){
      this.props.onChange(values);
    }

  };
  render() {
    const { client, onSubmitSuccess, onSubmitError, onChange, disabled, isDisabled, fetchClients } = this.props;
    const options = [
      { value: "reference1", label: "Reference 1" },
      { value: "reference2", label: "Reference 2" },
      { value: "reference3", label: "Reference 3" },
      { value: "reference4", label: "Reference 4" },
      { value: "reference5", label: "Reference 5" },
      { value: "reference6", label: "Reference 6" },
      { value: "reference7", label: "Reference 7" },
      { value: "reference8", label: "Reference 8" },
      { value: "reference9", label: "Reference 9" },
      { value: "reference10", label: "Reference 10" },
    ];
    const initialReference = client?.externalReference?.referenceName 
    ? options.find(opt => opt.value === client.externalReference.referenceName)
    : null;
    return (
      <Formik
      innerRef={(ref) => (this.formikRef = ref)}
        initialValues={{
          showDropdown: client?.externalReference?.isReferenced || false,
          reference: initialReference,
        }}
        onSubmit={async (values) => {
          try {
            const response = await axiosPatch(
              `/add-client/${client._id}/external-reference`,
              {
                externalReference: {
                  isReferenced: values.showDropdown,
                  referenceName: values.reference?.value || ""
                }
              }
            );
            if (onSubmitSuccess) {
              onSubmitSuccess(response);
            }
            return response;
          } catch (error) {
            if (onSubmitError) {
              onSubmitError(error);
            }
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
   
            <div className="d-flex flex-column">

              <Label className="form-check-label">
              <Field
                type="checkbox"
                name="showDropdown"
                checked={values.showDropdown}
                onChange={(e) => {
                    const newValues = {
                      ...values,
                      showDropdown: e.target.checked,
                      reference: e.target.checked ? values.reference : null
                    };
                    setFieldValue("showDropdown", e.target.checked);
                    if (!e.target.checked) {
                      setFieldValue("reference", null);
                    }
                    this.handleFormChange(newValues);
                  }}
                disabled={disabled}
                className="form-check-input me-1"
              />Yes</Label>
              {values.showDropdown && (
                <div className="mt-2">
                  <Field
                    name="reference"
                    component={Select}
                    options={options}
                    value={values.reference}
                    onChange={(option) => {
                      setFieldValue("reference", option);
                      this.handleFormChange({ ...values, reference: option });
                    }}
                    isSearchable
                    isClearable
                    menuPortalTarget={document.body}
                    className=""
                    disabled={disabled}
                    isDisabled={isDisabled}  // Disable the field here

                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default RefComponent;