import PropTypes from "prop-types";
import React, { Component } from "react";

// SimpleBar
import SimpleBar from "simplebar-react";

// withRouter and Link
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";

// i18n
import { withTranslation } from "react-i18next";
// metismenu
import MetisMenu from "metismenujs";
import { UserContext } from "components/UserProvider/UserProvider";
import { capitalizeFirstLetter, userRoles } from "constants/constants";

class SidebarContent extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      departmentObj: {
        shelter: "shelter",
        settlement: "settlement",
        orientation: "orientation",
      },
    };
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  hasActiveChild = childLinks => {
    const { pathname } = this.props.location;
    return childLinks.some(link => pathname.startsWith(link));
  };

  renderDepartmentContent = department => {
    if (department?.name === "shelter") {
      return (
        <>
          <NavLink
            to={`/department/${this.state.departmentObj[department?.name]}`}
            className="has-arrow waves-effect"
          >
            {this.props.t(
              `${
                department?.name.charAt(0).toUpperCase() +
                department?.name.slice(1)
              } Services`
            )}
          </NavLink>
          <ul className="sub-menu" aria-expanded="false">
            <li>
              <NavLink
                to={`/department/${this.state.departmentObj[department?.name]}`}
              >
                <span>
                  {this.props.t(
                    `${
                      department?.name.charAt(0).toUpperCase() +
                      department?.name.slice(1)
                    }`
                  )}
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/client-departure">
                <span>{this.props.t("Pre-departure FollowUps")}</span>
              </NavLink>
            </li>
          </ul>
        </>
      );
    }
    return (
      <NavLink to={`/department/${this.state.departmentObj[department?.name]}`}>
        {this.props.t(
          `${
            department?.name.charAt(0).toUpperCase() + department?.name.slice(1)
          } Services`
        )}
      </NavLink>
    );
  };

  render() {
    const { userData, departments } = this.context;
    const { departmentObj } = this.state;

    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>
              <li>
                <NavLink to="/home" className="waves-effect">
                  <i className="mdi mdi-home" />
                  <span>{this.props.t("Home")}</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-clipboard-account" />
                  <span>{this.props.t("Client Management")}</span>
                </NavLink>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <NavLink to="/add-clients">
                      <span>{this.props.t("Add Clients")}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/clients/draft">
                      <span>{this.props.t("Draft Clients")}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/clients/list" className="">
                      <span>{this.props.t("Client List")}</span>
                    </NavLink>
                  </li>
                  {/* {userData?.role === "ADMIN" && ( */}
                  <>
                    <li>
                      <NavLink to="/admin/clients/review">
                        <span>{this.props.t("Review Clients")}</span>
                      </NavLink>
                    </li>
                    {/* <li>
                            <NavLink to="/admin/clients/reviewEdit">
                              <span>{this.props.t("Review Edited Clients")}</span>
                            </NavLink>
                          </li> */}
                  </>
                  {/* )} */}
                  <li>
                    <NavLink to="/admin/clients/approved">
                      <span>{this.props.t("Approved for MH service")}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/admin/clients/declined">
                      <span>{this.props.t("Referred to external")}</span>
                    </NavLink>
                  </li>
                  <li>
                <NavLink to="/admin/referrals">
                  <span>{this.props.t("Referrals")}</span>
                </NavLink>
              </li>
                </ul>
              </li>

              <li>
                <NavLink to="/admin/reference-notes" className="waves-effect">
                  <i className="mdi mdi-note" />
                  <span>{this.props.t("Reference Notes")}</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-equal-box" />
                  <span>{this.props.t("Departments")}</span>
                </NavLink>
                <ul className="sub-menu" aria-expanded="false">
                  {userData.role !== "admin"
                    ? userData?.departments?.map((department, index) => (
                        <li key={`dept-${index}`}>
                          {this.renderDepartmentContent(department)}
                        </li>
                      ))
                    : departments?.map((department, index) => (
                        <li key={`dept-${index}`}>
                          {this.renderDepartmentContent(department)}
                        </li>
                      ))}
                </ul>
              </li>
              {/* <li>
                <NavLink to="/admin/client-departure" className="waves-effect">
                  <i className="mdi mdi-calendar-clock" />
                  <span>{this.props.t("Pre-departure FollowUps")}</span>
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/admin/follow-ups" className="waves-effect">
                  <i className="mdi mdi-calendar-check" />
                  <span>{this.props.t("Post-departure FollowUps")}</span>
                </NavLink>
              </li>

              {/* <li>
                <NavLink to="/admin/notifications" className="waves-effect">
                  <i className="mdi mdi-bell" />
                  <span>{this.props.t("Notifications")}</span>
                </NavLink>
              </li> */}

              {userData?.role === "admin" && (
                <>
                  <li className="menu-title">
                    {this.props.t("User Management")}
                  </li>
                  <li>
                    <NavLink to="/admin/users" className="waves-effect">
                      <i className="mdi mdi-account-group" />
                      <span>{this.props.t("Users")}</span>
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
