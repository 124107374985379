import Limit from "components/pagination/Limit";
import Pagination from "components/pagination/Pagination";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";
import { axiosPost, axiosPatch, axiosGet } from "helpers/api_helpers";
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Form, FormGroup, Input, Label, Alert, Badge } from "reactstrap";

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      customFileName: "",
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      page: PAGE,
      loading: false,
      uploadedFileUrl: null,
      columns: [
        {
          dataField: "_id",
          text: "Sr.No",
          formatter: (cell, row, rowIndex) =>
            (this.state.page - 1) * this.state.size + rowIndex + 1,
        },
        {
          dataField: "customFileName",
          text: "File Name",
        },
        {
          dataField: "createdAt",
          text: "Upload Date",
          formatter: cell => new Date(cell).toLocaleDateString(),
        },
        {
          dataField: "actions",
          text: "Actions",
          formatter: (cell, row, rowIndex) => (
            <Badge color="primary-subtle">
            <a
            key={rowIndex}
            href={row.fileUrl}
            target="_blank"
            rel="noreferrer"
            // className={`common-button-color d-inline-flex align-items-center p-2 gap-2`}
          >
            <i className="bx bxs-download font-size-16"></i>
          </a>
          </Badge>
          ),
        },
      ], // New state to store the uploaded file URL
      downloading: null
    };
  }

  handlePageClick = selectedPage => {
    this.setState({ page: selectedPage });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 });
  };

  componentDidMount() {
    const { getClientData, files } = this.props;
    getClientData();
    this.setState({ totalSize: files?.length });
  }

  handleFileSelect = async event => {
    const file = event.target.files[0];
    if (!file) return;

    this.setState({
      selectedFile: file,
      customFileName: file.name,
      loading: true,
    });

    // Upload file immediately when selected
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);

      const uploadResponse = await axiosPost("upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const url = uploadResponse?.data?.destination?.replace(/["""]/g, "");
      if (!url) {
        throw new Error("No valid URL received from server");
      }

      this.setState({
        uploadedFileUrl: url,
      });
    } catch (error) {
      this.setState({
        selectedFile: null,
        customFileName: "",
        uploadedFileUrl: null,
      });
      console.error("error uploading a file:", error);
      // Reset file input
      event.target.value = "";
    } finally {
      this.setState({ loading: false });
    }
  };

  handleFileNameChange = event => {
    this.setState({ customFileName: event.target.value });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { getClientData } = this.props;
    const { selectedFile, customFileName, uploadedFileUrl } = this.state;

    try {
      this.setState({ loading: true });

      // Update client's files with the already uploaded file info
      const fileData = {
        fileUrl: uploadedFileUrl,
        customFileName: customFileName,
        originalFileName: selectedFile.name,
      };

      await axiosPatch(`/add-client/${this.props.clientId}/files`, {
        fileData: fileData,
      });

      // Refresh the file list and reset form
      await getClientData();
      this.setState({
        selectedFile: null,
        customFileName: "",
        uploadedFileUrl: null,
      });

      // Reset file input
      event.target.reset();
    } catch (error) {
      console.error("error saving the file:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, customFileName, uploadedFileUrl, columns, page, size, totalSize } =
    this.state;
    const { files } = this.props;
    const currentFiles = files?.slice((page - 1) * size, page * size);
    return (
      <div className="p-4">
        <h2 className="mb-4">File Upload</h2>
        {uploadedFileUrl && (
          <Alert color="success">
            File uploaded successfully! Please provide a name to complete the
            process.
          </Alert>
        )}
        {/* Upload Form */}
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Label for="fileUpload">Select File</Label>
            <Input
              type="file"
              id="fileUpload"
              onChange={this.handleFileSelect}
              disabled={loading}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="fileName">File Name</Label>
            <Input
              type="text"
              id="fileName"
              value={customFileName}
              onChange={this.handleFileNameChange}
              placeholder="Enter file name"
              required
            />
          </FormGroup>
          <Button
            color="primary"
            type="submit"
            disabled={loading || !uploadedFileUrl}
          >
            {loading ? "Saving..." : "Save File"}
          </Button>
        </Form>
        <div
          className={`table-responsive table-striped ${
            currentFiles?.length === 0 ? "display-none" : ""
          }`}
        >
          <BootstrapTable
            keyField="_id"
            data={currentFiles}
            columns={columns}
            bordered={false}
            striped={false}
            responsive
          />
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Limit value={size} onChange={this.handleLimitChange} />
            <Pagination
              totalSize={totalSize}
              handlePageClick={this.handlePageClick}
              currentPage={page - 1}
              currentSize={size}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default FileUpload;
