import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import SingleSelectWithOther from "components/Common/SingleSelectWithOther";
import * as Yup from "yup";
import {
  canadaEntry,
  currentStatus,
  formKey,
  formStatus,
  provincesAndCities,
  status,
  subFormKey,
} from "constants/constants";

import Select from "react-select";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import {
  setShowPageLoader,
  toggleGeneralInfo,
  toggleImmigrationInfo,
  toggleInquiryInfo,
} from "store/actions";
import CustomDatePicker from "components/Common/DateField";
const initialValues = {
  province: "",
  city: "",
  startDate: "",
  endDate: "",
  SelectedStatus: "",
  SelectedStatusOtherText: "",
  windsorDate: "",
  canadaEntry: "",
  canadaEntryOtherText: "",
  canadaDate: "",
  referenceNotes: "",
};

const validationSchema = Yup.object().shape({
  province: Yup.string(),
  city: Yup.string(),
  canadaDate: Yup.string().required("Please enter the date"),
  canadaEntry: Yup.string().required("Please select an entry point"),
  canadaEntryOtherText: Yup.string().when("canadaEntry", {
    is: "Other",
    then: Yup.string().required("Please enter the other entry point"),
    otherwise: Yup.string().notRequired(),
  }),
  windsorDate: Yup.string().required("Please enter the date"),
  startDate: Yup.date(),
  endDate: Yup.date()
    .required("End date is required")
    .test(
      "is-greater-than-start-date",
      "End date must be later than start date",
      function (endDate) {
        const startDate = this.parent.startDate;
        return startDate && endDate && endDate > startDate;
      }
    ),
  currentStatus: Yup.string().required("Please select your current status"),
  currentStatusOtherText: Yup.string().when("currentStatus", {
    is: "Other",
    then: Yup.string().required("Please enter the other status"),
    otherwise: Yup.string().notRequired(),
  }),
});

class ImmigrationInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguages: [],
      initialValues: { ...initialValues },
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = async () => {
    const { clientData, referenceNote } = this.props;
    var refNote = "";
    if (referenceNote) {
      let latestNote = null;
      for (const note of referenceNote) {
        if (
          note.form === formKey.SCREENING &&
          note.subForm === subFormKey.IMMIGRATION &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const tempData = {
      canadaDate:
        (clientData?.canadaDate
          ? new Date(clientData?.canadaDate).toISOString().split("T")[0]
          : "") || "",
      canadaEntry: clientData?.canadaEntry || "",
      canadaEntryOtherText: clientData?.canadaEntryOtherText || "",
      windsorDate:
        (clientData?.windsorDate
          ? new Date(clientData?.windsorDate).toISOString().split("T")[0]
          : "") || "",
      province: clientData?.province || "",
      city: clientData?.city || "",
      startDate:
        (clientData?.startDate
          ? new Date(clientData?.startDate).toISOString().split("T")[0]
          : "") || "",
      endDate:
        (clientData?.endDate
          ? new Date(clientData?.endDate).toISOString().split("T")[0]
          : "") || "",
      currentStatus: clientData?.currentStatus || "",
      currentStatusOtherText: clientData?.currentStatusOtherText || "",
      referenceNotes: refNote || "",
    };
    this.setState({
      initialValues: tempData,
    });
  };

  handleSubmit = async (values, {setSubmitting, resetForm}) => {
    const {
      clientId,
      generalInfo,
      immigrationInfo,
      inquiryInfo,
      toggleGeneralInfo,
      toggleImmigrationInfo,
      toggleInquiryInfo,
      getClientData,
      setShowPageLoader,
    } = this.props;
    try {
      setShowPageLoader(true);
      const requestBody = {
        type: {
          subForm_key: subFormKey.IMMIGRATION,
          formStatus: status.COMPLETED,
        },
        canadaDate: values.canadaDate,
        canadaEntry: values.canadaEntry,
        canadaEntryOtherText: values.canadaEntryOtherText,
        windsorDate: values.windsorDate,
        province: values.province,
        city: values.city,
        startDate: values.startDate,
        endDate: values.endDate,
        currentStatus: values.currentStatus,
        currentStatusOtherText: values.currentStatusOtherText,
      };
      const response = await axiosPatch(
        `/add-client/screening/${clientId}/immigration`,
        {
          requestBody: requestBody,
          referenceNotes: values?.referenceNotes,
        }
      );
      if (response?.status) {
        setShowPageLoader(false);
        toast.success(response?.message || "Client Updated Successfully");
        getClientData();
        if (immigrationInfo) {
          toggleImmigrationInfo();
        }
        if (generalInfo) {
          toggleGeneralInfo();
        }
        if (!inquiryInfo) {
          toggleInquiryInfo();
        }
        setSubmitting(false);
        resetForm({values});
      } else {
        setShowPageLoader(false);
        toast.error(response?.message || "Oops! something went wrong");
        setSubmitting(false);
      }
    } catch (error) {
      setShowPageLoader(false);
      console.error("handleSubmit API error", error);
      setSubmitting(false);
    }
  };
  render() {
    const { clientData } = this.props;
    return (
      <React.Fragment>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={this.state.initialValues}
            validationSchema={validationSchema}
            onSubmit={
              this.handleSubmit
            }
          >
            {({ errors, touched, values, setFieldValue, dirty }) => (
              <Form>
                <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Arrival Details</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label for="canadaDate" className="form-label">
                                When did you arrive in Canada?
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                id="canadaDate"
                                name="canadaDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors.canadaDate && touched.canadaDate
                                    ? " is-invalid"
                                    : "")
                                }
                                value={new Date(values.canadaDate).toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto"
                                })}
                                placeholder="Enter the date"
                              /> */}
                              <CustomDatePicker
                                className={
                                  "form-control" +
                                  (errors?.canadaDate && touched?.canadaDate
                                    ? " is-invalid"
                                    : "")
                                }
                                selected={
                                  values?.canadaDate ? values.canadaDate : null
                                }
                                onChange={date => {
                                  if (date) {
                                    setFieldValue("canadaDate", date);
                                  } else {
                                    setFieldValue("canadaDate", null);
                                  }
                                }}
                                placeholderText="YYYY-MM-DD"
                              />
                              <ErrorMessage
                                name="canadaDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label for="windsorDate" className="form-label">
                                When did you arrive in Windsor?
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                id="windsorDate"
                                name="windsorDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors.windsorDate && touched.windsorDate
                                    ? " is-invalid"
                                    : "")
                                }
                                value={new Date(values.windsorDate).toLocaleDateString("en-CA", {
                                  timeZone: "America/Toronto"
                                })}
                                placeholder="Enter the date"
                              /> */}
                              <CustomDatePicker
                                className={
                                  "form-control" +
                                  (errors?.windsorDate && touched?.windsorDate
                                    ? " is-invalid"
                                    : "")
                                }
                                selected={
                                  values?.windsorDate
                                    ? values.windsorDate
                                    : null
                                }
                                onChange={date => {
                                  if (date) {
                                    setFieldValue("windsorDate", date);
                                  } else {
                                    setFieldValue("windsorDate", null);
                                  }
                                }}
                                placeholderText="YYYY-MM-DD"
                              />
                              <ErrorMessage
                                name="windsorDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <SingleSelectWithOther
                                name="canadaEntry"
                                label="Where did you enter Canada?"
                                options={canadaEntry}
                                values={values}
                                value={values?.canadaEntry}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                otherLabel="Other entry point"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label for="windsorDate" className="form-label">
                                If you stayed elsewhere in Canada before
                                arriving in Windsor, please list and state
                                length of time.
                              </Label>
                              <Label className="form-label fw-normal">
                                Province
                              </Label>
                              <Select
                                id="province"
                                name="province"
                                classNamePrefix="react-select"
                                value={
                                  values.province
                                    ? {
                                        value: values.province,
                                        label: values.province,
                                      }
                                    : null
                                }
                                options={Object.keys(provincesAndCities).map(
                                  province => ({
                                    value: province,
                                    label: province,
                                  })
                                )}
                                onChange={option => {
                                  setFieldValue("province", option?.value);
                                  setFieldValue("city", null);
                                }}
                                className={
                                  errors.province && touched.province
                                    ? " is-invalid"
                                    : ""
                                }
                                placeholder="Select province"
                              />
                              <ErrorMessage
                                name="province"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label className="form-label fw-normal">
                                City
                              </Label>
                              <Select
                                id="city"
                                name="city"
                                classNamePrefix="react-select"
                                options={
                                  values.province
                                    ? provincesAndCities[
                                        values.province
                                      ].cities.map(city => ({
                                        value: city.value,
                                        label: city.label,
                                      }))
                                    : []
                                }
                                value={
                                  values.city
                                    ? { value: values.city, label: values.city }
                                    : null
                                }
                                onChange={option =>
                                  setFieldValue("city", option?.value)
                                }
                                className={
                                  errors.city && touched.city
                                    ? " is-invalid"
                                    : ""
                                }
                                isDisabled={!values.province}
                                placeholder="Select city"
                              />
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Row>
                                <div className="col-md-6 mb-3">
                                  <Label
                                    for="startDate"
                                    className="form-label fw-normal"
                                  >
                                    Start date
                                  </Label>

                                  {/* <Field
                                    id="startDate"
                                    name="startDate"
                                    type="date"
                                    className={`form-control ${
                                      errors.startDate && touched.startDate
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    value={new Date(values.startDate).toLocaleDateString("en-CA", {
                                      timeZone: "America/Toronto"
                                    })}
                                    placeholder="Enter the date"
                                  /> */}
                                  <CustomDatePicker
                                    className={
                                      "form-control" +
                                      (errors?.startDate && touched?.startDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    selected={
                                      values?.startDate
                                        ? values.startDate
                                        : null
                                    }
                                    onChange={date => {
                                      if (date) {
                                        setFieldValue("startDate", date);
                                      } else {
                                        setFieldValue("startDate", null);
                                      }
                                    }}
                                    placeholderText="YYYY-MM-DD"
                                  />
                                  <ErrorMessage
                                    name="startDate"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                                <div className="col-md-6 mb-3">
                                  <Label
                                    for="endDate"
                                    className="form-label fw-normal"
                                  >
                                    End date
                                  </Label>
                                  {/* <Field
                                    id="endDate"
                                    name="endDate"
                                    type="date"
                                    className={`form-control ${
                                      errors.endDate && touched.endDate
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    value={new Date(values.endDate).toLocaleDateString("en-CA", {
                                      timeZone: "America/Toronto"
                                    })}
                                    min={new Date(values.startDate).toLocaleDateString("en-CA", {
                                      timeZone: "America/Toronto"
                                    })}
                                    disabled={!new Date(values.startDate).toLocaleDateString("en-CA", {
                                      timeZone: "America/Toronto"
                                    })}
                                    validate={value => {
                                      if (
                                        value &&
                                        new Date(value).toLocaleDateString("en-CA", {
                                          timeZone: "America/Toronto"
                                        }) <=
                                          new Date(values.startDate).toLocaleDateString("en-CA", {
                                            timeZone: "America/Toronto"
                                          })
                                      ) {
                                        return "End date must be greater than start date";
                                      }
                                      return undefined;
                                    }}
                                    placeholder="Enter the date"
                                  /> */}
                                  <CustomDatePicker
                                    className={
                                      "form-control" +
                                      (errors?.endDate && touched?.endDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    min={new Date(values.startDate)}
                                    disabled={!values.startDate}
                                    selected={
                                      values?.endDate ? values.endDate : null
                                    }
                                    onChange={date => {
                                      if (date) {
                                        setFieldValue("endDate", date);
                                      } else {
                                        setFieldValue("endDate", null);
                                      }
                                    }}
                                    placeholderText="YYYY-MM-DD"
                                  />
                                  <ErrorMessage
                                    name="endDate"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </Row>
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Status</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <SingleSelectWithOther
                                name="currentStatus"
                                label="What is your current status in Canada?"
                                options={currentStatus}
                                values={values}
                                value={values?.currentStatus}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                otherLabel="Other status"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Row>
                        <Col md="3">
                          <h4 className="form-label">Reference Notes</h4>
                        </Col>
                        <Col md="9">
                          <Field
                            id="referenceNotes"
                            name="referenceNotes"
                            as="textarea"
                            style={{ resize: "none" }}
                            className="form-control"
                            placeholder="Enter any notes or references"
                          />
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Row>
                  <Col>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                      <Button type="submit" className="common-button-color">
                        {clientData?.type?.formStatus === formStatus.COMPLETED
                          ? (dirty
                            ? "Save"
                            : "Edit")
                          : "Save & Next"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

// export default ImmigrationInfo;
const mapStateToProps = state => ({
  generalInfo: state.Clients.generalInfo,
  immigrationInfo: state.Clients.immigrationInfo,
  inquiryInfo: state.Clients.inquiryInfo,
});

const mapDispatchToProps = dispatch => ({
  toggleGeneralInfo: () => dispatch(toggleGeneralInfo()),
  toggleImmigrationInfo: () => dispatch(toggleImmigrationInfo()),
  toggleInquiryInfo: () => dispatch(toggleInquiryInfo()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImmigrationInfo);
