import React, { Component, Fragment } from "react";
import { Col, Row, Modal, ModalHeader, ModalBody, Label } from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { axiosPatch, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  genderOptions,
  userRoles,
  userRolesOptions,
} from "constants/constants";
import ModalLoader from "components/Common/ModalLoader";
// import ModalLoader from "components/Common/ModalLoader";

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLoader: false,
      departmentOptions: [],
      departments: [],
      selectedDepartment: "",
    };
  }

  componentDidMount = () => {
    const { isEdit, user, departments } = this.props;
    // this.getDepartments();
    const departmentOptions = departments.map(department => ({
      label: department.name,
      value: department.id, // Assuming the ID field is named "_id"
    }));
    this.setState({ departmentOptions });
    if (isEdit) {
      this.setState({
        departments: user?.departments,
        selectedDepartment: user.departments,
      });
    }
  };

  handleMulti = (selectedDepartment, setFieldValue) => {
    this.setState({ selectedDepartment });
    setFieldValue("departments", selectedDepartment);
  };

  // getDepartments = async () => {
  //   try {
  //     const response = await axiosGet("/department");
  //     if (response.status) {
  //       const departmentOptions = response.data.results.map(department => ({
  //         label: department.name,
  //         value: department._id, // Assuming the ID field is named "_id"
  //       }));
  //       this.setState({ departmentOptions });
  //     }
  //   } catch (error) {
  //     console.error("error at getDepartments :", error);
  //   }
  // };

  addUser = async userData => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllUsers, toggle } = this.props;
      const response = await axiosPost("users", userData);
      if (response?.status) {
        toast.success(response?.message || "user added successfully!");
        getAllUsers();
        toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error while adding user :", error);
    }
  };

  editUser = async userData => {
    try {
      this.setState({ showModalLoader: true });
      const { getAllUsers, toggle, user } = this.props;
      const response = await axiosPatch(`users/${user?.id}`, userData);
      if (response?.status) {
        toast.success(response?.message || "user updated successfully!");
        getAllUsers();
        toggle();
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      this.setState({ showModalLoader: false });
      console.error("error while editing user :", error);
    }
  };

  handleOnSubmit = values => {
    try {
      const { isEdit, user, toggle } = this.props;
      let departments = [];
      if (values["departments"]) {
        departments = values["departments"].map(department => department.value);
      }
      const data = {
        email: values["email"],
        name: values["name"],
        phoneNumber: `${values["phoneNumber"]}`,
        gender: values["gender"],
        role: values["role"],
        departments: departments,
        isEmailVerified: true,
        isActivated: true,
      };
      if (!isEdit) {
        this.addUser(data);
      } else if (isEdit) {
        const editedFields = Object.keys(values).reduce((acc, key) => {
          if (values[key] !== user[key] && values[key]) {
            if (key === "departments") {
              acc[key] = departments;
            } else {
              acc[key] = `${values[key]}`;
            }
          }
          return acc;
        }, {});

        if (Object.keys(editedFields).length > 0) {
          const editedData = {
            ...editedFields,
          };
          this.editUser(editedData);
        } else {
          toggle();
        }
      }
    } catch (error) {
      console.error("error at handleOnSubmit :", error);
    }
  };

  render() {
    const { isEdit, showUserModal, toggle, user } = this.props;
    const { showModalLoader, departmentOptions } = this.state;
    const initialValues = {
      name: (user && user.name) || "",
      gender: (user && user.gender) || "",
      email: (user && user.email) || "",
      phoneNumber: (user && user.phoneNumber) || "",
      departments: (user && user.departments) || "",
      role: (user && user.role) || "",
    };

    const validationSchema = Yup.object().shape({
      name: Yup.string().required("Please enter user name"),
      gender: Yup.string().required("Please select user gender"),
      email: Yup.string().required("Please enter user's email"),
      phoneNumber: Yup.number().required("Please enter user's phone number"),
      departments: Yup.array()
        .of(Yup.object())
        .when("role", {
          is: role =>
            [
              userRoles.ADMIN,
              userRoles.STAFF,
              userRoles.DEPARTMENT_MANAGER,
            ].includes(role),
          then: Yup.array()
            .of(Yup.object())
            .required("Please select a department"),
          otherwise: Yup.array().of(Yup.object()),
        }),
      role: Yup.string().required("Please enter user's role"),
    });

    return (
      <Modal isOpen={showUserModal} className={this.props.className}>
        {showModalLoader ? <ModalLoader /> : ""}
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit User Details" : "Add User"}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleOnSubmit}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">Name</Label>
                      <Field
                        name="name"
                        type="text"
                        placeholder="Enter the full name"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Field
                        name="email"
                        type="email"
                        placeholder="Enter the email"
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Gender</Label>

                      <div className="">
                        {Object.keys(genderOptions).map((key, index) => {
                          return (
                            <Fragment key={`${key}-${index}`}>
                              <Field
                                name="gender"
                                type="radio"
                                value={genderOptions[key]}
                                id={genderOptions[key]}
                                className="form-check-input me-1"
                              />
                              <Label
                                className="form-check-label me-5"
                                htmlFor="male"
                              >
                                {key}
                              </Label>
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Phone Number</Label>
                      <Field
                        name="phoneNumber"
                        type="number"
                        placeholder="Enter the phone number"
                        className={
                          "form-control" +
                          (errors.phoneNumber && touched.phoneNumber
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Role</Label>
                      <Field
                        as="select"
                        name="role"
                        className={
                          "form-control-select" +
                          (errors.role && touched.role ? " is-invalid" : "")
                        }
                      >
                        <option value="">Select role</option>
                        {Object.keys(userRolesOptions).map(key => {
                          return (
                            <option key={key} value={userRolesOptions[key]}>
                              {key}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage
                        name="role"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {values.role === userRoles.ADMIN ||
                    values.role === userRoles.DEPARTMENT_MANAGER ||
                    values.role === userRoles.STAFF ? (
                      <div className="mb-3">
                        <Label className="form-label">Departments</Label>
                        <Select
                          value={this.state.selectedDepartment}
                          isMulti={true}
                          onChange={selectedValue =>
                            this.handleMulti(selectedValue, setFieldValue)
                          }
                          options={departmentOptions}
                          classNamePrefix="select2-selection"
                          className={
                            "" +
                            (errors.departments && touched.departments
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="departments"
                          component="div"
                          className="invalid-feedback"
                        />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

export default UserForm;
