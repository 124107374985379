import React, { Component } from 'react';
import { limitRange } from 'constants/constants';

class Limit extends Component {
  render() {
    const { value, onChange } = this.props;
    return (
      <div>
        <select id="limit" className="btn btn-default btn-secondary dropdown-toggle" value={value} onChange={onChange}>
        {limitRange.map((optionValue) => (
          <option key={optionValue} value={optionValue}>
            {optionValue}
          </option>
        ))}
        </select>
      </div>
    );
  }
}

export default Limit;