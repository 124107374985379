import React from "react";
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

class CustomDatePicker extends React.Component {
  handleChange = date => {
    const { onChange } = this.props;

    if (date) {
      const formattedDate = format(date, "yyyy-MM-dd");
      onChange(formattedDate);
    } else {
      onChange(null);
    }
  };

  render() {
    const { selected, className, placeholderText, min, max, disabled } =
      this.props;
    const selectedDate = selected ? parseISO(selected) : null;
    return (
      <DatePicker
        placeholderText={placeholderText}
        className={className}
        selected={selectedDate}
        onChange={this.handleChange}
        dateFormat="yyyy-MM-dd"
        maxDate={max}
        minDate={min}
        disabled={disabled}
      />
    );
  }
}

export default CustomDatePicker;
