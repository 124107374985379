import React from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { axiosPatch } from "helpers/api_helpers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formKey, formStatus, status, subFormKeyIntake } from "constants/constants";

import {
  togglePersonalInfo,
  toggleIntakeEducationalBackground,
  toggleIntakeEmploymentHistory,
  toggleIntakeHealthInfo,
  toggleIntakeHousingSection,
  toggleIntakeImmigrationInfo,
} from "store/actions";

const formatUCI = uci => {
  if (uci.length < 8 || uci.length > 10 || uci.length == 9) {
    return uci;
  }
  // Remove hyphens and commas using replace()
  const cleanedUci = uci.replace(/[-,]/g, "");

  // Format based on cleanedUci length (using a switch statement for clarity)
  switch (cleanedUci.length) {
    case 8:
      return `${cleanedUci.slice(0, 4)}-${cleanedUci.slice(4)}`;
    case 10:
      return `${cleanedUci.slice(0, 2)}-${cleanedUci.slice(
        2,
        6
      )}-${cleanedUci.slice(6)}`;
    default:
      return uci; // Unreachable but added for completeness
  }
};

class IntakeApproval extends React.Component {
  constructor(props) {
    super(props);
    this.signatureRef = React.createRef();
  }
  handleSubmit = async values => {
    const { clientId, getClientData } = this.props;

    if (clientId) {
      try {
        const requestBody = {
          type: {
            // is_completed: true,
            form_key: formKey.INTAKE,
          },
          intakeApprovalInformation: {
            type: {
              subForm_key: subFormKeyIntake.APPROVAL,
              formStatus: status.COMPLETED,
            },
            welfareId: values.welfareId,
            immigrationId: values.immigrationId,
            accountNumber: values.accountNumber,
          },
        };
        const response = await axiosPatch(
          `/add-client/intake/${clientId}/intakeApproval`,
          requestBody
        );
        if (response?.status) {
  
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
        } else {
       
          toast.error(response?.message || "Oops! something went wrong");
        }
      } catch (error) {
        
        console.error("handleSubmit API error", error);
      }
    }
  };
  render() {
    const { intakeData ,clientData,mainData} = this.props;
    const initialValues = {
      welfareId: intakeData?.welfareId || "",
      immigrationId: intakeData?.immigrationId || clientData?.uci ||"",
      accountNumber: intakeData?.accountNumber || mainData?.householdId|| "",
    };

    const validationSchema = Yup.object().shape({
      welfareId: Yup.string().required("Please enter your welfare Id"),
      accountNumber: Yup.string().required("Please enter your account number"),
      immigrationId: Yup.string()
      .matches(/^\d{8}$|^\d{10}$/, "UCI must be either 8 or 10 digits")
      .required("Please Enter Your UCI"),
    });
    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <div className="col-md-6 ml-10">
                      <Row>
                        <Label className="form-label mt-2">
                          Welfare ID
                          <span className="text-danger">*</span>
                        </Label>
                        <Col md="9">
                          <Field
                            type="text"
                            name="welfareId"
                            placeholder="Enter the welfare Id"
                            className={
                              "form-control" +
                              (errors.welfareId && touched.welfareId
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="welfareId"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Label className="form-label mt-2">
                          Immigration ID
                          <span className="text-danger">*</span>
                        </Label>
                        <Col md="9">
                          <Field
                            type="text"
                            name="immigrationId"
                            readOnly={true}
                            className={
                              "form-control  disabled-style" +
                              (errors.immigrationId && touched.immigrationId
                                ? " is-invalid"
                                : "")
                            }
                            value={formatUCI(values?.immigrationId)}
                                               
                            onChange={e => {
                              const inputValue = e.target.value.replace(
                                /[^\d]/g,
                                ""
                              ); // Remove non-digit characters
                              setFieldValue(`immigrationId`, inputValue);
                            }}
                          />
                          <ErrorMessage
                            name="immigrationId"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Label className="form-label mt-2">
                          Account number
                          <span className="text-danger">*</span>
                        </Label>
                        <Col md="9">
                          <Field
                            type="text"
                            name="accountNumber"
                            className={
                              "form-control" +
                              (errors.accountNumber && touched.accountNumber
                                ? " is-invalid"
                                : "")
                            }
                            disabled
                          />
                          <ErrorMessage
                            name="accountNumber"
                            component="div"
                            className="invalid-feedback text-danger"
                          />
                        </Col>
                      </Row>
                    </div>

                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button type="submit" className="common-button-color">
                          {intakeData?.type?.formStatus ===
                        formStatus.COMPLETED
                          ? dirty
                            ? "Save"
                            : "Edit"
                          : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntakeApproval));
