import React from "react";
import { Field, ErrorMessage } from "formik";
import { Label, Col, Row, Button } from "reactstrap";
import { relation, gender } from "constants/constants";
import Select from "react-select";
import CustomDatePicker from "components/Common/DateField";

const formatUCI = uci => {
  if (uci.length < 8 || uci.length > 10 || uci.length == 9) {
    return uci;
  }
  // Remove hyphens and commas using replace()
  const cleanedUci = uci.replace(/[-,]/g, "");

  // Format based on cleanedUci length (using a switch statement for clarity)
  switch (cleanedUci.length) {
    case 8:
      return `${cleanedUci.slice(0, 4)}-${cleanedUci.slice(4)}`;
    case 10:
      return `${cleanedUci.slice(0, 2)}-${cleanedUci.slice(
        2,
        6
      )}-${cleanedUci.slice(6)}`;
    default:
      return uci; // Unreachable but added for completeness
  }
};

class AddMembers extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleRemove, handleAdd, errors, touched, setFieldValue, values } =
      this.props;
    return (
      <div>
        {values?.memberForms?.map((member, index) => (
          <div key={index}>
            <Row className="mb-3">
              <Col md="6">
                <Label
                  for={`memberForms.${index}.memberUci`}
                  className="form-label"
                >
                  UCI#
                </Label>
                <Field
                  id={`memberForms.${index}.memberUci`}
                  name={`memberForms.${index}.memberUci`}
                  type="text"
                  placeholder="Enter UCI Id"
                  value={formatUCI(values.memberForms[index].memberUci)}
                  onChange={e => {
                    const inputValue = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
                    setFieldValue(`memberForms.${index}.memberUci`, inputValue);
                  }}
                  className={
                    "form-control" +
                    (errors.memberForms &&
                    touched.memberForms &&
                    errors.memberForms[index] &&
                    touched.memberForms[index] &&
                    errors.memberForms[index].memberUci &&
                    touched.memberForms[index].memberUci
                      ? " is-invalid"
                      : "")
                  }
                />
                <ErrorMessage
                  name={`memberForms.${index}.memberUci`}
                  component="div"
                  className="invalid-feedback"
                />
              </Col>
            </Row>
            <Row>
              <div className="col-md-4 mb-3">
                <Label
                  for={`memberForms.${index}.memberFirstName`}
                  className="form-label"
                >
                  First name<span className="text-danger">*</span>
                </Label>
                <Field
                  id={`memberForms.${index}.memberFirstName`}
                  name={`memberForms.${index}.memberFirstName`}
                  type="text"
                  className={
                    "form-control" +
                    (errors.memberForms &&
                    touched.memberForms &&
                    errors.memberForms[index] &&
                    touched.memberForms[index] &&
                    errors.memberForms[index].memberFirstName &&
                    touched.memberForms[index].memberFirstName
                      ? " is-invalid"
                      : "")
                  }
                  onChange={e =>
                    setFieldValue(
                      `memberForms.${index}.memberFirstName`,
                      e.target.value
                    )
                  }
                  placeholder="Enter first name"
                />
                <ErrorMessage
                  name={`memberForms.${index}.memberFirstName`}
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="col-md-4 mb-3">
                <Label
                  for={`memberForms.${index}.memberLastName`}
                  className="form-label"
                >
                  Last name<span className="text-danger">*</span>
                </Label>
                <Field
                  id={`memberForms.${index}.memberLastName`}
                  name={`memberForms.${index}.memberLastName`}
                  type="text"
                  className={
                    "form-control" +
                    (errors.memberForms &&
                    touched.memberForms &&
                    errors.memberForms[index] &&
                    touched.memberForms[index] &&
                    errors.memberForms[index].memberLastName &&
                    touched.memberForms[index].memberLastName
                      ? " is-invalid"
                      : "")
                  }
                  onChange={e =>
                    setFieldValue(
                      `memberForms.${index}.memberLastName`,
                      e.target.value
                    )
                  }
                  placeholder="Enter last name"
                />
                <ErrorMessage
                  name={`memberForms.${index}.memberLastName`}
                  component="div"
                  className="invalid-feedback"
                />
              </div>

              <div className="col-md-4 mb-3">
                <Label
                  for={`memberForms.${index}.memberMiddleName`}
                  className="form-label"
                >
                  Middle name
                </Label>
                <Field
                  id={`memberForms.${index}.memberMiddleName`}
                  name={`memberForms.${index}.memberMiddleName`}
                  type="text"
                  className="form-control"
                  onChange={e =>
                    setFieldValue(
                      `memberForms.${index}.memberMiddleName`,
                      e.target.value
                    )
                  }
                  placeholder="Enter middle name"
                />
              </div>
            </Row>
            <Row className="mb-3">
              <Col md="6">
                <Label
                  for={`memberForms.${index}.memberGender`}
                  className="form-label"
                >
                  Gender<span className="text-danger">*</span>
                </Label>
                <Select
                  id={`memberForms.${index}.memberGender`}
                  name={`memberForms.${index}.memberGender`}
                  options={gender}
                  value={gender.find(
                    option =>
                      option.value === values.memberForms[index].memberGender
                  )}
                  onChange={option =>
                    setFieldValue(
                      `memberForms.${index}.memberGender`,
                      option.value
                    )
                  }
                  className={
                    errors.memberForms &&
                    touched.memberForms &&
                    errors.memberForms[index] &&
                    touched.memberForms[index] &&
                    errors.memberForms[index].memberGender &&
                    touched.memberForms[index].memberGender
                      ? " is-invalid"
                      : ""
                  }
                  placeholder="Select gender"
                />
                <ErrorMessage
                  name={`memberForms.${index}.memberGender`}
                  component="div"
                  className="invalid-feedback"
                />
              </Col>

              <Col md="6">
                <Label
                  for={`memberForms.${index}.memberDob`}
                  className="form-label"
                >
                  Date of birth<span className="text-danger">*</span>
                </Label>
                <CustomDatePicker
                  className={
                    "form-control" +
                    (errors?.memberForms?.[index]?.memberDob &&
                    touched?.memberForms?.[index]?.memberDob
                      ? " is-invalid"
                      : "")
                  }
                  selected={
                    values.memberForms?.[index]?.memberDob
                      ? values.memberForms?.[index]?.memberDob
                      : null
                  }
                  onChange={date => {
                    if (date) {
                      setFieldValue(`memberForms.${index}.memberDob`, date);
                    } else {
                      setFieldValue(`memberForms.${index}.memberDob`, null);
                    }
                  }}
                  placeholderText="YYYY-MM-DD"
                  max={new Date()}
                />
                <ErrorMessage
                  name={`memberForms.${index}.memberDob`}
                  component="div"
                  className="invalid-feedback"
                />
              </Col>
            </Row>
            <Row>
              <div className="col-md-6 mb-3">
                <Label
                  for={`memberForms.${index}.memberRelation`}
                  className="form-label"
                >
                  Relation to lead client<span className="text-danger">*</span>
                </Label>
                <Select
                  id={`memberForms.${index}.memberRelation`}
                  name={`memberForms.${index}.memberRelation`}
                  options={relation}
                  value={relation.find(
                    option =>
                      option.value === values.memberForms[index].memberRelation
                  )}
                  onChange={option =>
                    setFieldValue(
                      `memberForms.${index}.memberRelation`,
                      option.value
                    )
                  }
                  className={
                    errors.memberForms &&
                    touched.memberForms &&
                    errors.memberForms[index] &&
                    touched.memberForms[index] &&
                    errors.memberForms[index].memberRelation &&
                    touched.memberForms[index].memberRelation
                      ? " is-invalid"
                      : ""
                  }
                  placeholder="Enter relation to lead client"
                />
                <ErrorMessage
                  name={`memberForms.${index}.memberRelation`}
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <Col>
                <div className="d-flex justify-content-end mt-4">
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => handleRemove(index)}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        ))}
        <Button
          type="button"
          className="common-button-color mb-2"
          onClick={handleAdd}
        >
          <i className="fas fa-plus" /> Add Member
        </Button>
      </div>
    );
  }
}

export default AddMembers;
