import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Card, CardBody, Button, Row, Col, Label } from 'reactstrap';
import SignatureCanvas from 'react-signature-canvas';
import  CustomDatePicker  from '../../components/Common/DateField';
import { formKey, formStatus, status, subFormKeyIntake } from '../../constants/constants';
import { setShowPageLoader, toggleIntakeTenancyAgreement } from 'store/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosPatch } from 'helpers/api_helpers';

class TenancyAgreementSection extends React.Component {
  constructor(props) {
    super(props);
    this.tenancySignatureRef = React.createRef();
  }

  handleSubmit = async values => {
    const {
      clientId,
      getClientData,
      setShowPageLoader,
      toggleTenancyAgreement,
    } = this.props;

    if (clientId) {
      try {
        setShowPageLoader(true);
        const requestBody = {
          type: {
            form_key: formKey.INTAKE,
          },
          tenancyAgreement: {
            type: {
              subForm_key: subFormKeyIntake.TENANCY_AGREEMENT,
              formStatus: status.COMPLETED,
            },
            rentAmount: values.rentAmount,
            tenancySignature: values.tenancySignature,
            tenancyDate: values.tenancyDate,
          },
        };
        
        const response = await axiosPatch(
          `/add-client/intake/${clientId}/tenancy`,
          {
            requestBody: requestBody,
          }
        );

        if (response?.status) {
          setShowPageLoader(false);
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
          if (toggleTenancyAgreement) {
            toggleIntakeTenancyAgreement();
          }
        } else {
          setShowPageLoader(false);
          toast.error("handleSubmit API error", response?.message);
        }
      } catch (error) {
        setShowPageLoader(false);
        toast.error("handleSubmit API error", error);
      }
    }
  };

  render() {
    const { intakeData } = this.props;

    const initialValues = {
      rentAmount: intakeData?.rentAmount || "",
      tenancySignature: intakeData?.tenancySignature || "",
      tenancyDate: intakeData?.tenancyDate
        ? new Date(intakeData?.tenancyDate).toISOString().split("T")[0]
        : "",
    };

    const validationSchema = Yup.object().shape({
      rentAmount: Yup.number()
        .required("Rent amount is required")
        .min(0, "Rent amount must be positive"),
      tenancySignature: Yup.string().required("Signature is required"),
      tenancyDate: Yup.date().required("Please enter date"),
    });

    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Tenancy Agreement</h4>
                        </Col>
                        <Col md="9">
                          <div className="form-group">
                            <Label className="mb-2">
                              I have been made aware of the rules of Matthew House and agree to abide by them. 
                              I agree to pay the rent of{" "}
                              <span className="d-inline-flex align-items-center">
                                <span className="me-1">$</span>
                                <Field
                                  type="number"
                                  placeholder="Enter amount"
                                  className={
                                    "form-control w-auto" +
                                    (errors.rentAmount && touched.rentAmount ? " is-invalid" : "")
                                  }
                                  name="rentAmount"
                                />
                              </span>{" "}
                              per month once I am able for my room and board. I am aware that my room may be 
                              searched if there is reason for staff to do so. I am also aware that failing to 
                              follow Matthew House rules may result in me being evicted.
                            </Label>
                            <ErrorMessage
                              name="rentAmount"
                              component="div"
                              className="invalid-feedback text-danger"
                            />
                          </div>

                          <div className="d-flex gap-5 align-items-start mt-2">
                            <div>
                              <Label className="form-label">
                                Signature of resident
                                <span className="text-danger">*</span>
                              </Label>
                              {values?.tenancySignature ? (
                                <img
                                  src={values?.tenancySignature}
                                  alt="Signature"
                                  className="canvas"
                                  onError={event => {
                                    event.target.style.display = "none";
                                    console.error("Failed to load Signature image:", values?.tenancySignature);
                                  }}
                                />
                              ) : (
                                <Field name="tenancySignature">
                                  {({ field }) => (
                                    <>
                                      <SignatureCanvas
                                        ref={this.tenancySignatureRef}
                                        canvasProps={{
                                          className: "canvas",
                                          width: 150,
                                          height: 55,
                                        }}
                                        onEnd={() => {
                                          const data = this?.tenancySignatureRef?.current?.toDataURL();
                                          setFieldValue("tenancySignature", data || "");
                                        }}
                                        {...field}
                                      />
                                      <ErrorMessage
                                        name="tenancySignature"
                                        component="div"
                                        className={`invalid-feedback text-danger ${
                                          errors.tenancySignature ? "d-block" : ""
                                        }`}
                                      />
                                    </>
                                  )}
                                </Field>
                              )}
                              <div className="mt-1">
                                <button
                                  type="button"
                                  className="btn btn-secondary p-1 pl-2 font-sm"
                                  onClick={() => {
                                    setFieldValue("tenancySignature", "");
                                    this.tenancySignatureRef?.current?.clear();
                                  }}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                            <div className="margin-date">
                              <Row>
                                <Label className="form-label">
                                  Date
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col md="9">
                                  <CustomDatePicker
                                    className={
                                      "form-control" +
                                      (errors?.tenancyDate && touched?.tenancyDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    selected={values.tenancyDate ? values.tenancyDate : null}
                                    onChange={date => {
                                      setFieldValue("tenancyDate", date || null);
                                    }}
                                    placeholderText="YYYY-MM-DD"
                                  />
                                  <ErrorMessage
                                    name="tenancyDate"
                                    component="div"
                                    className="invalid-feedback text-danger"
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button type="submit" className="common-button-color">
                            {intakeData?.type?.formStatus === formStatus.COMPLETED
                              ? dirty
                                ? "Save"
                                : "Edit"
                              : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  tenancyAgreement: state.Clients.tenancyAgreement,
});

const mapDispatchToProps = dispatch => ({
  toggleIntakeTenancyAgreement: () => dispatch(toggleIntakeTenancyAgreement()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TenancyAgreementSection));