import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { departments, LIMIT, PAGE, SIZE_PER_PAGE } from "constants/constants";
import { axiosGet } from "helpers/api_helpers";
import emptyContacts from "../../assets/images/emptyContact.png";
import { connect } from "react-redux";
import { setShowPageLoader } from "store/actions";
import Limit from "../../components/pagination/Limit";
import Pagination from "../../components/pagination/Pagination";
import { FormControl } from "react-bootstrap";

class Orientation extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      orientationClients: [],
      size: SIZE_PER_PAGE,
      page: PAGE,
      totalSize: 0,
      orientationClient: "",
      contactListColumns: [
        {
          text: "UCI",
          dataField: "screeningForm.generalInformation.uci",
          sort: true,
        },
        {
          text: "Name",
          dataField: "intakeForm.personalInformation.leadMemberName",
          sort: true,
          formatter: (cellContent, row) => {
            return (
              <span>{`${row?.screeningForm?.generalInformation?.firstName} ${row?.screeningForm?.generalInformation?.middleName} ${row?.screeningForm?.generalInformation?.lastName}`}</span>
            );
          },
        },
        {
          text: "Email",
          dataField: "screeningForm.generalInformation.email",
          sort: true,
        },
        {
          text: "Phone No",
          dataField: "screeningForm.generalInformation.phoneNumber",
          sort: true,
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, client) => (
            <div className="d-flex gap-3">
              <Link
                className="text-success"
                to={`/department/orientation/${client.id}`}
              >
                <i
                  className="mdi mdi-clipboard-edit font-size-18"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                  id="edit"
                  // onClick={() => this.handleUserClick(opt)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
  }

  componentDidMount = () => {
    this.handleGetOrientationDepartmentClients();
  };
  handlePageClick = selectedPage => {
    this.setState({ page: selectedPage }, () => {
      this.handleGetOrientationDepartmentClients();
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetOrientationDepartmentClients();
    });
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ orientationClients: [] }, () => {
        this.handleGetOrientationDepartmentClients();
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchTerm: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };
  handleGetOrientationDepartmentClients = async () => {
    const { showPageLoader } = this.props;
    try {
      showPageLoader(true);
      const response = await axiosGet(
        `/department?page=1&limit=${LIMIT}&name=${departments.Orientation}&populate=clients`
      );
      if (response?.status) {
        this.setState({
          orientationClients: response?.data?.results?.[0]?.clients || [],
          totalSize: response?.data?.totalResults || 0,
        });
      } 
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      console.error("error at handleGetOrientationDepartmentClients :", error);
    }
  };

  render() {
    //meta title
    document.title = "Department | Orientation";

    const { orientationClients } = this.state;
   
    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    return (
      <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Department" breadcrumbItem="Settlement" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {orientationClients.length ? (
                    <Col sm="3">
                      <div className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchTerm}
                            onChange={e => {
                              this.searchUsers(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchUsers(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                  <div className="d-flex justify-content-center">
                    <img
                      src={emptyContacts}
                      alt="empty-contact"
                      className={`${
                        orientationClients.length === 0 ? "" : "display-none"
                      }`}
                    />
                  </div>
                  <div
                    className={`table-responsive table-striped ${
                      orientationClients.length === 0 ? "display-none" : ""
                    }`}
                  >
                    <BootstrapTable
                      keyField="id"
                      data={orientationClients}
                      columns={this.state.contactListColumns}
                      defaultSorted={defaultSorted}
                      classes={"table align-middle table-nowrap"}
                      bordered={false}
                      striped={false}
                      responsive
                      ref={this.node}
                    />
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Limit
                        value={this.state.size}
                        onChange={this.handleLimitChange}
                      />
                      <Pagination
                        totalSize={this.state.totalSize}
                        handlePageClick={this.handlePageClick}
                        currentPage={this.state.page - 1}
                        currentSize={this.state.size}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

const mapStateToProps = ({ user }) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Orientation));

// export default Orientation;
