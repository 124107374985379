import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { setShowPageLoader } from "store/actions";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import CustomDatePicker from "components/Common/DateField";

const validationSchema = Yup.object().shape({
  memberDetails: Yup.array().of(
    Yup.object().shape({
      radioOption: Yup.string().required("Required"),
      referredDate: Yup.date().when("radioOption", {
        is: "No",
        then: Yup.date().required("Required"),
      }),
      bankName: Yup.string().when("radioOption", {
        is: "No",
        then: Yup.string().required("Required"),
      }),
      appointmentDate: Yup.date().when("radioOption", {
        is: "No",
        then: Yup.date().required("Required"),
      }),
      myBenefitsOrientationDate: Yup.date().required("Required"),
    })
  ),
});

class BankDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;

    try {
      const leadClientData = {
        memberHouseholdId: clientData.screeningForm?.generalInformation?.memberHouseholdId,
        uci: clientData.screeningForm?.generalInformation?.uci,
        firstName: clientData.screeningForm?.generalInformation?.firstName,
        lastName: clientData.screeningForm?.generalInformation?.lastName,
        middleName: clientData.screeningForm?.generalInformation?.middleName,
        gender: clientData.screeningForm?.generalInformation?.gender,
        dateOfBirth: clientData.screeningForm?.generalInformation?.dateOfBirth,
        relation: "Self",
      };
      // Create array with lead client data and all members' data
      const allMembersData = [
        leadClientData,
        ...clientData.screeningForm?.generalInformation?.members,
      ];

      this.setState({
        allMembers: allMembersData,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  handleMemberSelect = (selectedOptions, values, setFieldValue) => {
    const selectedValues = selectedOptions.map(option => {
      const matchedRestriction = values.memberDetails.find(
        member => member.memberId === option.value
      );
      if (matchedRestriction) {
        return matchedRestriction;
      }
      return ({
          memberId: option.value,
          memberName: option.label,
          radioOption: "",
          referredDate: "",
          bankName: "",
          appointmentDate: "",
          myBenefitsOrientationDate: "",
        }
      );
    });
    setFieldValue("memberDetails", selectedValues);
  };

  handleAddShelterBankDetailsData = async bankDetailsInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/shelter/bankDetails/${clientData?.id}`,
        bankDetailsInfo
      );
      if (response?.status) {
        toast.success(response?.message || "Bank details stored successfully!");
        handleGetClient();
        toggle("6");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      bankDetailsInfo: {
        memberDetails:
          values?.memberDetails.map(detail => ({
            memberId: detail?.memberId,
            radioOption: detail?.radioOption,
            ...(detail?.referredDate && { referredDate: detail?.referredDate }),
            ...(detail?.bankName && { bankName: detail?.bankName }),
            ...(detail?.appointmentDate && {
              appointmentDate: detail?.appointmentDate,
            }),
            myBenefitsOrientationDate: detail?.myBenefitsOrientationDate,
          })) || [],
      },
    };
    this.handleAddShelterBankDetailsData(data);
  };

  render() {
    const { allMembers } = this.state;

    const { clientData } = this.props;
    const initalBankDetails =
    clientData?.shelter?.bankDetails?.memberDetails
      ?.map(memberDetail => {
        const foundMember = allMembers.find(m => (m.memberHouseholdId) === memberDetail.memberId);
        if (foundMember) {
          return {
            ...memberDetail,
            memberName: `${foundMember.firstName || ""} ${
              foundMember.middleName || ""
            } ${foundMember.lastName || ""}`.trim(),
            radioOption: memberDetail?.radioOption || "",
            referredDate:memberDetail?.referredDate ? new Date(memberDetail?.referredDate).toISOString().split("T")[0] : "" || "",
            bankName: memberDetail?.bankName || "",
            appointmentDate:memberDetail?.appointmentDate ? new Date(memberDetail?.appointmentDate).toISOString().split("T")[0] : "" || "",
            myBenefitsOrientationDate:
            memberDetail?.myBenefitsOrientationDate ? new Date(memberDetail?.myBenefitsOrientationDate).toISOString().split("T")[0] : "",
          };
        }
        return null;
      })
      .filter(Boolean) || [];
    const initialValues = {
      memberDetails: initalBankDetails,
    };

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Bank Details</h4>
                        </Col>
                        <Col md="9">
                          <Label>Select Members</Label>
                          <Select
                            className="col-md-4"
                            id="memberSelect"
                            name="memberSelect"
                            value={values.memberDetails.map(
                              member => ({
                                value: member.memberId,
                                label: member.memberName,
                              })
                            )}
                            options={allMembers.map(member => ({
                              value: (member.memberHouseholdId),
                              label: `${member.firstName || ""} ${
                                member.middleName || ""
                              } ${member.lastName || ""}`.trim(),
                            }))}
                            isMulti
                            onChange={selectedOption => {
                              this.handleMemberSelect(
                                selectedOption,
                                values,
                                setFieldValue
                              );
                            }}
                          />
                          <ErrorMessage
                            name="memberSelect"
                            component="div"
                            className="text-danger"
                          />
                          <div className="mb-3">
                            <FieldArray name="memberDetails">
                              {({ insert, remove, push }) => (
                                <div className="mt-2">
                                  {values?.memberDetails?.length > 0 &&
                                    values?.memberDetails?.map(
                                      (member, index) => (
                                        <div key={index}>
                                          <div className="mt-4">
                                            <label className="form-label">
                                              <h4>{member?.memberName}</h4>
                                            </label>
                                          </div>
                                          <div className="col-md-4 mb-3">
                                            <label
                                              htmlFor={`memberDetails.${index}.radioOption`}
                                              className="form-label"
                                            >
                                              Do you have a bank account?
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              as="select"
                                              id={`memberDetails.${index}.radioOption`}
                                              name={`memberDetails.${index}.radioOption`}
                                              className={
                                                "form-control" +
                                                (errors?.memberDetails &&
                                                errors?.memberDetails[index] &&
                                                errors?.memberDetails[index]
                                                  .radioOption &&
                                                touched?.memberDetails &&
                                                touched?.memberDetails[index] &&
                                                touched?.memberDetails[index]
                                                  .radioOption
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            >
                                              <option value="">Select</option>
                                              <option value="Yes">Yes</option>
                                              <option value="No">No</option>
                                            </Field>
                                            <ErrorMessage
                                              name={`memberDetails.${index}.radioOption`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                          {values.memberDetails[index]
                                            .radioOption === "No" && (
                                            <>
                                              <div className="col-md-4 mb-3">
                                                <label
                                                  htmlFor={`memberDetails.${index}.referredDate`}
                                                  className="form-label"
                                                >
                                                  Referred Date
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                {/* <Field
                                                  id={`memberDetails.${index}.referredDate`}
                                                  name={`memberDetails.${index}.referredDate`}
                                                  type="date"
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails &&
                                                    errors?.memberDetails[
                                                      index
                                                    ] &&
                                                    errors?.memberDetails[index]
                                                      .referredDate &&
                                                    touched?.memberDetails &&
                                                    touched?.memberDetails[
                                                      index
                                                    ] &&
                                                    touched?.memberDetails[
                                                      index
                                                    ].referredDate
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                /> */}
                                                <CustomDatePicker
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails?.[
                                                      index
                                                    ]?.referredDate &&
                                                    touched?.memberDetails?.[
                                                      index
                                                    ]?.referredDate
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  selected={
                                                    values?.memberDetails?.[
                                                      index
                                                    ]?.referredDate
                                                      ? values.memberDetails?.[
                                                          index
                                                        ]?.referredDate
                                                      : null
                                                  }
                                                  onChange={date => {
                                                    if (date) {
                                                      setFieldValue(
                                                        `memberDetails.${index}.referredDate`,
                                                        date
                                                      );
                                                    } else {
                                                      setFieldValue(
                                                        `memberDetails.${index}.referredDate`,
                                                        null
                                                      );
                                                    }
                                                  }}
                                                  placeholderText="YYYY-MM-DD"
                                                />
                                                <ErrorMessage
                                                  name={`memberDetails.${index}.referredDate`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                              <div className="col-md-4 mb-3">
                                                <label
                                                  htmlFor={`memberDetails.${index}.bankName`}
                                                  className="form-label"
                                                >
                                                  Bank Name
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  id={`memberDetails.${index}.bankName`}
                                                  name={`memberDetails.${index}.bankName`}
                                                  type="text"
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails &&
                                                    errors?.memberDetails[
                                                      index
                                                    ] &&
                                                    errors?.memberDetails[index]
                                                      .bankName &&
                                                    touched?.memberDetails &&
                                                    touched?.memberDetails[
                                                      index
                                                    ] &&
                                                    touched?.memberDetails[
                                                      index
                                                    ].bankName
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                />
                                                <ErrorMessage
                                                  name={`memberDetails.${index}.bankName`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                              <div className="col-md-4 mb-3">
                                                <label
                                                  htmlFor={`memberDetails.${index}.appointmentDate`}
                                                  className="form-label"
                                                >
                                                  Appointment Date
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                {/* <Field
                                                  id={`memberDetails.${index}.appointmentDate`}
                                                  name={`memberDetails.${index}.appointmentDate`}
                                                  type="date"
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails &&
                                                    errors?.memberDetails[
                                                      index
                                                    ] &&
                                                    errors?.memberDetails[index]
                                                      .appointmentDate &&
                                                    touched?.memberDetails &&
                                                    touched?.memberDetails[
                                                      index
                                                    ] &&
                                                    touched?.memberDetails[
                                                      index
                                                    ].appointmentDate
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                /> */}
                                                <CustomDatePicker
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails?.[
                                                      index
                                                    ]?.appointmentDate &&
                                                    touched?.memberDetails?.[
                                                      index
                                                    ]?.appointmentDate
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  selected={
                                                    values?.memberDetails?.[
                                                      index
                                                    ]?.appointmentDate
                                                      ? values.memberDetails?.[
                                                          index
                                                        ]?.appointmentDate
                                                      : null
                                                  }
                                                  onChange={date => {
                                                    if (date) {
                                                      setFieldValue(
                                                        `memberDetails.${index}.appointmentDate`,
                                                        date
                                                      );
                                                    } else {
                                                      setFieldValue(
                                                        `memberDetails.${index}.appointmentDate`,
                                                        null
                                                      );
                                                    }
                                                  }}
                                                  placeholderText="YYYY-MM-DD"
                                                />
                                                <ErrorMessage
                                                  name={`memberDetails.${index}.appointmentDate`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                            </>
                                          )}
                                          <div className="col-md-4 mb-3">
                                            <label
                                              htmlFor={`memberDetails.${index}.myBenefitsOrientationDate`}
                                              className="form-label"
                                            >
                                              Mybenefits Orientation Date
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            {/* <Field
                                              id={`memberDetails.${index}.myBenefitsOrientationDate`}
                                              name={`memberDetails.${index}.myBenefitsOrientationDate`}
                                              type="date"
                                              className={
                                                "form-control" +
                                                (errors?.memberDetails &&
                                                errors?.memberDetails[index] &&
                                                errors?.memberDetails[index]
                                                  .myBenefitsOrientationDate &&
                                                touched?.memberDetails &&
                                                touched?.memberDetails[index] &&
                                                touched?.memberDetails[index]
                                                  .myBenefitsOrientationDate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            /> */}
                                            <CustomDatePicker
                                              className={
                                                "form-control" +
                                                (errors?.memberDetails?.[index]
                                                  ?.myBenefitsOrientationDate &&
                                                touched?.memberDetails?.[index]
                                                  ?.myBenefitsOrientationDate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              selected={
                                                values?.memberDetails?.[index]
                                                  ?.myBenefitsOrientationDate
                                                  ? values.memberDetails?.[
                                                      index
                                                    ]?.myBenefitsOrientationDate
                                                  : null
                                              }
                                              onChange={date => {
                                                if (date) {
                                                  setFieldValue(
                                                    `memberDetails.${index}.myBenefitsOrientationDate`,
                                                    date
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    `memberDetails.${index}.myBenefitsOrientationDate`,
                                                    null
                                                  );
                                                }
                                              }}
                                              placeholderText="YYYY-MM-DD"
                                            />
                                            <ErrorMessage
                                              name={`memberDetails.${index}.myBenefitsOrientationDate`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                          {index <
                                          values?.memberSelect?.length - 1 ? (
                                            <hr />
                                          ) : null}
                                        </div>
                                      )
                                    )}
                                </div>
                              )}
                            </FieldArray>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn common-button-color" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BankDetails));
