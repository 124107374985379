import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { setShowPageLoader } from "store/actions";
import { axiosPatch, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import CustomDatePicker from "components/Common/DateField";

const validationSchema = Yup.object().shape({
  memberDetails: Yup.array().of(
    Yup.object().shape({
      date: Yup.date().required("Required"),
      name: Yup.string().required("Required"),
      agency: Yup.string().required("Required"),
    })
  ),
});

class Employement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
      url: "",
      uploadStatus: {},
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;

    try {
      const leadClientData = {
        memberHouseholdId:
          clientData.screeningForm?.generalInformation?.memberHouseholdId,
        uci: clientData.screeningForm?.generalInformation?.uci,
        firstName: clientData.screeningForm?.generalInformation?.firstName,
        lastName: clientData.screeningForm?.generalInformation?.lastName,
        middleName: clientData.screeningForm?.generalInformation?.middleName,
        gender: clientData.screeningForm?.generalInformation?.gender,
        dateOfBirth: clientData.screeningForm?.generalInformation?.dateOfBirth,
        relation: "Self",
      };
      // Create array with lead client data and all members' data
      const allMembersData = [
        leadClientData,
        ...clientData.screeningForm?.generalInformation?.members,
      ];

      this.setState({
        allMembers: allMembersData,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  // handleFileUpload = async (event, setFieldValue, fieldName, index, existingResumes = []) => {
  //   const file = event.target.files?.[0];
  //   if (!file) return;

  //   try {
  //     const formData = new FormData();
  //     formData.append("file", file);

  //     const uploadResponse = await axiosPost(`upload`, formData);
  //     let url = uploadResponse?.data?.destination;
  //     url = url?.replace(/["""]/g, "");

  //     // Create a new array with existing resumes and add the new one
  //     const updatedResumes = [...existingResumes, url];
      
  //     // Update the field value with the array of resumes
  //     setFieldValue(fieldName, updatedResumes);
      
  //     // Clear the file input
  //     event.target.value = "";
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     toast.error("Failed to upload resume");
  //   }
  // };
  handleFileUpload = async (event, setFieldValue, fieldName, index) => {
    const file = event.target.files?.[0];
    if (!file) return;
  
    this.setState(prevState => ({
      uploadStatus: {
        ...prevState.uploadStatus,
        [index]: 'uploading'
      }
    }));
  
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
  
      const uploadResponse = await axiosPost(`upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
  
      let url = uploadResponse?.data?.destination;
      if (!url) {
        throw new Error("No URL received from server");
      }
  
      url = url?.replace(/["""]/g, "");
      
      // Set only the new URL in the form values
      setFieldValue(`memberDetails.${index}.resume`, url);
  
      this.setState(prevState => ({
        uploadStatus: {
          ...prevState.uploadStatus,
          [index]: 'uploaded'
        }
      }));
  
      setTimeout(() => {
        this.setState(prevState => ({
          uploadStatus: {
            ...prevState.uploadStatus,
            [index]: null
          }
        }));
      }, 3000);
  
      event.target.value = "";
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(error.message || "Failed to upload resume");
      
      this.setState(prevState => ({
        uploadStatus: {
          ...prevState.uploadStatus,
          [index]: 'error'
        }
      }));
  
      setTimeout(() => {
        this.setState(prevState => ({
          uploadStatus: {
            ...prevState.uploadStatus,
            [index]: null
          }
        }));
      }, 3000);
    }
  };

  handleMemberSelect = (selectedOptions, values, setFieldValue) => {
    const selectedValues = selectedOptions.map(option => {
      const matchedRestriction = values.memberDetails.find(
        member => member.memberId === option.value
      );
      if (matchedRestriction) {
        return matchedRestriction;
      }
      return {
        memberId: option.value,
        memberName: option.label,
        date: "",
        name: "",
        agency: "",
        resume: "",
        resumes: [],
      };
    });
    setFieldValue("memberDetails", selectedValues);
  };

  getLatestTwoResumes = (resumes = []) => {
    return resumes.slice(-2);
  };

  handleAddShelterEmploymentData = async employmentInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/shelter/employment/${clientData?.id}`,
        employmentInfo
      );
      if (response?.status) {
        toast.success(
          response?.message || "Employment information stored successfully!"
        );
        handleGetClient();
        toggle("5");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      employmentInfo: {
        memberDetails:
          values?.memberDetails.map(detail => ({
            memberId: detail?.memberId,
            date: detail?.date,
            name: detail?.name,
            agency: detail?.agency,
            ...(detail.resume && { resume: detail.resume })
          })) || [],
      },
    };
    this.handleAddShelterEmploymentData(data);
  };

  render() {
    const getUploadStatusUI = (status) => {
      switch (status) {
        case 'uploading':
          return (
            <span className="text-warning d-inline-flex align-items-center ms-2">
              <i className="bx bx-loader bx-spin me-1"></i>
              Uploading...
            </span>
          );
        case 'uploaded':
          return (
            <span className="text-success d-inline-flex align-items-center ms-2">
              <i className="bx bx-check-circle me-1"></i>
              Uploaded Successfully
            </span>
          );
        case 'error':
          return (
            <span className="text-danger d-inline-flex align-items-center ms-2">
              <i className="bx bx-error-circle me-1"></i>
              Upload Failed
            </span>
          );
        default:
          return null;
      }
    };
    const { allMembers, uploadStatus } = this.state;

    const { clientData } = this.props;

    const initalEmploymentDetails =
      clientData?.shelter?.employment?.memberDetails
        ?.map(memberDetail => {
          const foundMember = allMembers.find(
            m => m.memberHouseholdId === memberDetail.memberId
          );
          if (foundMember) {
            return {
              ...memberDetail,
              memberName: `${foundMember.firstName || ""} ${
                foundMember.middleName || ""
              } ${foundMember.lastName || ""}`.trim(),
              date: new Date(memberDetail?.date).toISOString().split("T")[0],
              name: memberDetail?.name,
              agency: memberDetail?.agency,
              resume: "",
              resumes: memberDetail?.resumes || [],
            };
          }
          return null;
        })
        .filter(Boolean) || [];
    const initialValues = {
      // memberDetails: clientData?.shelter?.employment?.memberDetails?.length > 0 &&
      //   clientData?.shelter?.employment?.memberDetails.map(memberDetail => {
      //     const matchedMember = allMembers.find((client) => client.uci === memberDetail.uci)|| {}
      //     return {
      //       uci: matchedMember?.uci || "",
      //       memberName: `${matchedMember?.firstName} ${matchedMember?.middleName} ${matchedMember?.lastName}` || "",
      //       date: memberDetail?.date
      //         ? new Date(memberDetail?.date).toISOString().split("T")[0]
      //         : "" || "",
      //       name: memberDetail?.name || "",
      //       agency: memberDetail?.agency || "",
      //       resume: memberDetail?.resume?.replace(/["“”]/g, "") || "",
      //     }
      //   }) || [],
      memberDetails: initalEmploymentDetails,
    };

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
   
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Employment Supports</h4>
                        </Col>
                        <Col md="9">
                          <Label>Select Members</Label>
                          <Select
                            className="col-md-4"
                            id="memberSelect"
                            name="memberSelect"
                            value={values.memberDetails.map(member => ({
                              value: member.memberId,
                              label: member.memberName,
                            }))}
                            options={allMembers.map(member => ({
                              value: member.memberHouseholdId,
                              label: `${member.firstName || ""} ${
                                member.middleName || ""
                              } ${member.lastName || ""}`.trim(),
                            }))}
                            isMulti
                            onChange={selectedOption => {
                              this.handleMemberSelect(
                                selectedOption,
                                values,
                                setFieldValue
                              );
                            }}
                          />
                          <div className="mb-3">
                            <FieldArray name="memberDetails">
                              {({ insert, remove, push }) => (
                                <div className="mt-2">
                                  {values?.memberDetails?.length > 0 &&
                                    values?.memberDetails?.map(
                                      (member, index) => (
                                        <div key={index} className="">
                                          <div className="mt-4">
                                            <label className="form-label">
                                              <h4>{member?.memberName}</h4>
                                            </label>
                                          </div>
                                          <div className="d-flex">
                                            <div className="col-md-4 mb-3 me-3">
                                              <label
                                                htmlFor={`name${index}`}
                                                className="form-label"
                                              >
                                                Name
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <Field
                                                id={`name${index}`}
                                                name={`memberDetails.${index}.name`}
                                                placeholder="Enter Name"
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors?.memberDetails &&
                                                  errors?.memberDetails[
                                                    index
                                                  ] &&
                                                  errors?.memberDetails[index]
                                                    ?.name &&
                                                  touched?.memberDetails &&
                                                  touched?.memberDetails[
                                                    index
                                                  ] &&
                                                  touched?.memberDetails[index]
                                                    ?.name
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name={`memberDetails.${index}.name`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="col-md-4 mb-3 me-3">
                                              <label
                                                htmlFor={`agency${index}`}
                                                className="form-label"
                                              >
                                                Agency
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <Field
                                                id={`agency${index}`}
                                                name={`memberDetails.${index}.agency`}
                                                placeholder="Enter Agency"
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors?.memberDetails &&
                                                  errors?.memberDetails[
                                                    index
                                                  ] &&
                                                  errors?.memberDetails[index]
                                                    ?.agency &&
                                                  touched?.memberDetails &&
                                                  touched?.memberDetails[
                                                    index
                                                  ] &&
                                                  touched?.memberDetails[index]
                                                    ?.agency
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name={`memberDetails.${index}.agency`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                              <label
                                                htmlFor={`date${index}`}
                                                className="form-label"
                                              >
                                                Date
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              {/* <Field
                                                id={`date${index}`}
                                                name={`memberDetails.${index}.date`}
                                                placeholder="Enter date"
                                                type="date"
                                                className={
                                                  "form-control" +
                                                  (errors?.memberDetails &&
                                                  errors?.memberDetails[
                                                    index
                                                  ] &&
                                                  errors?.memberDetails[index]
                                                    ?.date &&
                                                  touched?.memberDetails &&
                                                  touched?.memberDetails[
                                                    index
                                                  ] &&
                                                  touched?.memberDetails[index]
                                                    ?.date
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              /> */}
                                              <CustomDatePicker
                                                className={
                                                  "form-control" +
                                                  (errors?.memberDetails?.[
                                                    index
                                                  ]?.date &&
                                                  touched?.memberDetails?.[
                                                    index
                                                  ]?.date
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                selected={
                                                  values?.memberDetails?.[index]
                                                    ?.date
                                                    ? values.memberDetails?.[
                                                        index
                                                      ]?.date
                                                    : null
                                                }
                                                onChange={date => {
                                                  if (date) {
                                                    setFieldValue(
                                                      `memberDetails.${index}.date`,
                                                      date
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      `memberDetails.${index}.date`,
                                                      null
                                                    );
                                                  }
                                                }}
                                                placeholderText="YYYY-MM-DD"
                                              />
                                              <ErrorMessage
                                                name={`memberDetails.${index}.date`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </div>

                                          <div className="col-md-4 mb-3">
                                            <label
                                              htmlFor={`memberDetails[${index}].resume`}
                                              className="form-label"
                                            >
                                              Resume
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id={`resume${index}`}
                                              name={`memberDetails[${index}].resume`}
                                              type="file"
                                              accept=".pdf,.docx,.doc"
                                              className={
                                                "form-control" +
                                                (errors?.memberDetails &&
                                                errors?.memberDetails[index] &&
                                                errors?.memberDetails[index]
                                                  ?.resume &&
                                                touched?.memberDetails &&
                                                touched?.memberDetails[index] &&
                                                touched?.memberDetails[index]
                                                  ?.resume
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              onChange={event => {
                                                const url =
                                                  this.handleFileUpload(
                                                event,
                                                setFieldValue,
                                                `memberDetails.${index}.resume`,
                                                index,
                                                values.memberDetails[index].resume || ""
                                              )
                                                // setFieldValue(
                                                //   `memberDetails[${index}].resumes`,
                                                //   url
                                                // );
                                              }}
                                              disabled={uploadStatus[index] === 'uploading'}
                                            />
                                            <ErrorMessage
                                              name={`memberDetails[${index}].resume`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          {getUploadStatusUI(uploadStatus[index])}
                                          </div>
                                          {member?.resumes?.length > 0 && (
                                            <div className="mt-2">
                                              <p className="mb-2">
                                                Latest Resume's:
                                              </p>
                                              <div className="d-flex gap-2">
                                                {this.getLatestTwoResumes(
                                                  member.resumes
                                                ).map(
                                                  (
                                                    resumeUrl,
                                                    resumeIndex,
                                                    arr
                                                  ) => {
                                                    const isLatestResume =
                                                      resumeIndex ===
                                                      arr.length - 1;
                                                    return (
                                                      <a
                                                        key={resumeIndex}
                                                        href={resumeUrl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className={`btn ${
                                                          isLatestResume
                                                            ? "common-button-color"
                                                            : "btn-secondary"
                                                        } d-inline-flex align-items-center p-2 gap-2`}
                                                      >
                                                        <i className="fs-4 bx bxs-file"></i>
                                                        <span>
                                                          {isLatestResume
                                                            ? "Current Resume"
                                                            : "Previous Resume"}
                                                        </span>
                                                      </a>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          )}
                                          {index <
                                          values?.memberDetails?.length - 1 ? (
                                            <hr />
                                          ) : null}
                                        </div>
                                      )
                                    )}
                                </div>
                              )}
                            </FieldArray>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn common-button-color" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Employement));
