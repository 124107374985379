import React from 'react'
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from "reactstrap"
import newLogo1 from "../assets/images/oldlogo.png"

class HomePageContent extends React.Component {
  render() {
    return (
        <div className="page-content">
        {/* Hero Section */}
        <div className="text-white py-5 home-content">
          <Container className="text-center py-5">
            <h1 className="display-4 fw-bold mb-4 text-white">Welcome to Matthew House</h1>
            <p className="lead mb-4">Providing hope and support for Refugee Claimants</p>
          </Container>
        </div>
  
        {/* Mission Section */}
        <section className="py-5 mission-section">
          <Container>
            <h2 className="text-center fw-bold mb-5">Our Mission</h2>
            <Row className="align-items-center justify-content-center">
              <Col md={6} className="mb-4">
                <img
                  src={newLogo1}
                  alt="Matthew House Windsor"
                  className="img-fluid rounded shadow"
                  style={{ width: '90%', height: 'auto' }}
                />
              </Col>
              <Col md={6}>
                <p className="lead">
                  Matthew House Windsor brings hope by providing a temporary home and settlement 
                  supports for Refugee Claimants, empowering them to become contributing members 
                  of the Canadian community.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
  
        {/* Services Section */}
        <section className="bg-light-subtle py-5">
          <Container>
            <h2 className="text-center fw-bold mb-5">Our Services</h2>
            <Row>
              <Col md={4} className="mb-4">
                <Card className="h-100 shadow-sm mission-section">
                  <CardBody>
                    <CardTitle tag="h3" className="h4 fw-bold">
                      Temporary Housing
                    </CardTitle>
                    <CardText>
                      We provide safe and comfortable temporary housing for refugee claimants.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md={4} className="mb-4">
                <Card className="h-100 shadow-sm mission-section">
                  <CardBody>
                    <CardTitle tag="h3" className="h4 fw-bold">
                      Settlement Support
                    </CardTitle>
                    <CardText>
                      Our team offers guidance and support to help refugees settle into their 
                      new community.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
              <Col md={4} className="mb-4">
                <Card className="h-100 shadow-sm mission-section">
                  <CardBody>
                    <CardTitle tag="h3" className="h4 fw-bold">
                      Community Integration
                    </CardTitle>
                    <CardText>
                      We facilitate programs to help refugees integrate and contribute to 
                      Canadian society.
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
  
        {/* Call to Action Section */}
        {/* <section className="py-5">
          <Container className="text-center">
            <h2 className="fw-bold mb-4">Get Involved</h2>
            <p className="lead mb-4">
              Your support can make a difference in the lives of refugee claimants.
            </p>
            <div className="d-flex justify-content-center gap-3 flex-wrap">
              <Button color="primary" size="lg" className="px-4">
                Donate Now
              </Button>
              <Button color="success" size="lg" className="px-4">
                Volunteer
              </Button>
            </div>
          </Container>
        </section> */}
      </div>
    )
  }
}

export default HomePageContent