export const subFormKeyScreening = {
    GENERAL: "general",
    IMMIGRATION: "immigration",
    INQUIRY: "inquiry",
};

export const subFormKeyIntake = {
    PERSONAL: "personal",
    INTAKE_IMMIGRATION: "intakeImmigration",
    HEALTH: "health",
    EDUCATION: "education",
    EMPLOYMENT: "employment",
    HOUSING: "housing",
    APPROVAL: "approval",
    // HEALTH_CONSENT: "healthConsent",
    // MH_CONSENT: "mhConsent",
    // MH_RELEASE_CONSENT: "mhReleaseConsent",
};

export const formKey = {
    SCREENING: "screening",
    INTAKE: "intake",
    SHELTER: "shelter",
    SETTLEMENT: "settlement",
    ORIENTATION: "orientation"
};

export const settlementSubFormKey = {
    LEGALAID: "legalAid",
    LAWYER: "lawyer",
    IRCC: "ircc",
    IMMIGRATION_MEDICAL: "immigrationMedical",
    XRAY: "xray",
    WORK_PERMIT: "workPermit",
    HEARING_STATUS: "hearingStatus",
  };
  
  export const orientationSubFormKey = {
    WORKSHOPS: "workshops",
    REFERRAL: "referral",
    DAYCAMPPROGRAM: "dayCampProgram",
    CHRISTMASINTAKE: "christmasIntake",
  };

  export const shelterSubFormKey = {
    INTAKE: "intake",
    BASIC_NEEDS: "basicNeeds",
    EDUCATION: "education",
    EMPLOYMENT: "employment",
    BANK_DETAILS: "bankDetails",
    HOUSING: "housing",
    REFERRALS: "referrals",
    HOUSING_AGREEMENT: "housingAgreement",
    HOUSING_RULES: "housingRules",
    MEDICAL_INFORMATION: "medicalInformation",
  };