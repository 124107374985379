import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Button,
} from "reactstrap";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import { InputGroup, FormControl } from "react-bootstrap";
import { axiosGet, axiosPatch } from "helpers/api_helpers";
import {
  PAGE,
  SIZE_PER_PAGE,
  TOTAL_SIZE,
  screeningFormStatus,
  userRoles,
  capitalizeFirstLetter,
} from "constants/constants";
import { UserContext } from "components/UserProvider/UserProvider";
import moment from "moment";
import Breadcrumbs from "components/Common/Breadcrumb";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setShowPageLoader } from "store/actions";
import Pagination from "components/pagination/Pagination";
import Limit from "components/pagination/Limit";
import emptyContacts from "../../assets/images/emptyContact.png";
import RefComponent from "components/Common/ReferenceComponent";

class ReferralDepartment extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      updatedClients: new Set(),
      referenceUpdates: new Set(),
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      page: PAGE,
      searchTerm: "",
    };
    this.refComponents = new Map();
  }

  componentDidMount() {
    this.fetchClients();
  }
  handleReferenceChange = (clientId, values) => {
    if (!clientId) return;
    this.setState(prevState => {
      // Find the client in current state
      const updatedClients = prevState.clients.map(client => {
        if (client._id === clientId || client.id === clientId) {
          // Get the reference component instance
          
            // Checking if there are actual changes compared to current state
            const hasChanges = 
            client.externalReference?.isReferenced !== values.showDropdown ||
            client.externalReference?.referenceName !== values.reference?.value;
            // Update the client's external reference data
            return {
              ...client,
              externalReference: {
                ...client.externalReference,
                isReferenced: values.showDropdown,
                referenceName: values.reference?.value || ""
              }
            };
        }

        return client;
      });

      return {
        clients: updatedClients,
        referenceUpdates: new Set([...prevState.referenceUpdates, clientId])
      };
    });
  };


  async fetchClients() {
    try {
      this.props.showPageLoader(true);
      const { userData } = this.context;
      let response, fetchedClients;

      if (this.props.clientId) {
        response = await axiosGet(
          `/add-client/screening/${this.props.clientId}`
        );
        const client = response.data;
        fetchedClients = [
          {
            ...client,
            dropdownOptions: [],
            referral: client?.referralDepartments
              ? client.referralDepartments.map(dep => ({
                  name: dep.departmentName,
                }))
              : [],
          },
        ];
      } else {
        response = await axiosGet(
          `/add-client/screening?screeningApprovalStatus=${
            userData.role === userRoles.ADMIN
              ? screeningFormStatus.APPROVED
              : `${screeningFormStatus.APPROVED}`
          }&page=${this.state.page}&limit=${this.state.size}&searchString=${
            this.state.searchTerm
          }`
        );

        fetchedClients = response.data.results.map(client => ({
          ...client,
          dropdownOptions: [],
          referral: client?.referralDepartments
            ? client.referralDepartments.map(dep => ({
                name: dep.departmentName,
              }))
            : [],
        }));
      }

      this.setState(
        {
          clients: fetchedClients,
          totalSize: response?.data?.totalResults || 1,
        },
        () => {
          this.fetchDepartmentsForAllClients();
        }
      );
      this.props.showPageLoader(false);
    } catch (error) {
      this.props.showPageLoader(false);
      console.error(error);
    }
  }

  async fetchDepartmentsForAllClients() {
    try {
      const departmentResponses = await Promise.all(
        this.state.clients.map(() => axiosGet("/department"))
      );
      const updatedClients = this.state.clients.map((client, index) => ({
        ...client,
        dropdownOptions: departmentResponses[index]?.data?.results?.map(
          department => ({
            value: department.name,
            label: department.name,
          })
        ),
      }));
      this.setState({ clients: updatedClients });
    } catch (error) {
      console.error(error);
    }
  }

  handleReferralChange(clientId, selectedOptions) {
    this.setState(prevState => ({
      clients: prevState.clients.map(client =>
        client._id === clientId || client.id === clientId
          ? {
              ...client,
              referral: selectedOptions.map(option => ({
                name: option.value,
              })),
            }
          : client
      ),
      updatedClients: new Set([...prevState.updatedClients, clientId]),
    }));
  }

  async handleSaveAndNext(clientId) {

    const client = clientId
      ? this.state.clients.find(c => c?._id === clientId || c?.id === clientId)
      : this.state.clients[0];
    if (!client) {
      console.error(`Client with ID ${clientId} not found.`);
      return;
    }

    const referralDepartments = client.referral.map(referral => ({
      departmentName: referral.name,
    }));

    try {
      this.props.showPageLoader(true);
      const refComponent = this.refComponents.get(clientId);

      if (refComponent && this.state.referenceUpdates.has(clientId)) {
        // Submit reference form if there are changes
        await refComponent.submitForm();

        this.setState(prevState => ({
          referenceUpdates: new Set(
            [...prevState.referenceUpdates].filter(id => id !== clientId)
          ),
        }));
      }
      if (this.state.updatedClients.has(clientId)) {
        const referralDepartments = client.referral.map(referral => ({
          departmentName: referral.name,
        }));
        const response = await axiosPatch(`/add-client/referral/${clientId}`, {
          referralDepartments,
        });
        if (response.status) {
          toast.success("Client referred to departments successfully");
          this.setState(prevState => ({
            updatedClients: new Set(
              [...prevState.updatedClients].filter(id => id !== clientId)
            ),
          }));
          await this.fetchClients();
        }
      }
      this.props.showPageLoader(false);
    } catch (error) {
      this.props.showPageLoader(false);
      toast.error("Error at Referral department:", error);
    }
  }

  handlePageClick(selectedPage) {
    this.setState({ page: selectedPage });
  }

  handleLimitChange(event) {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 });
  }

  debounceSearch() {
    const WAITING_TIMING = 500;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fetchClients();
    }, WAITING_TIMING);
  }

  searchClients(event) {
    const { value } = event.target;
    this.setState({ searchTerm: value }, () => {
      this.debounceSearch();
    });
  }
  getColumns = () => {
    const { userData } = this.context;
    const isAdmin =
      userData.role === userRoles.ADMIN ||
      userData.role === userRoles.DEPARTMENT_MANAGER;
    const { updatedClients, referenceUpdates } = this.state;
    const { clientId } = this.props;

    return [
      {
        text: "Client Name",
        dataField: "screeningForm.generalInformation.firstName",
        sort: true,
        formatter: (cellContent, client) => {
          const middleName =
            client?.screeningForm?.generalInformation?.middleName;
          const lastName = client?.screeningForm?.generalInformation?.lastName;
          return `${capitalizeFirstLetter(cellContent)} ${capitalizeFirstLetter(
            middleName
          )} ${capitalizeFirstLetter(lastName)}`;
        },
      },
      {
        text: "Referred To",
        dataField: "referral",
        sort: false,
        formatter: (cellContent, client) => {
          const idClient = client?._id || client?.id;
          return (
            <Form>
              <FormGroup>
                <Select
                  isMulti
                  value={
                    client.referral
                      ? client.referral.map(option => ({
                          value: option.name,
                          label: option.name,
                        }))
                      : []
                  }
                  onChange={selectedOptions =>
                    this.handleReferralChange(idClient, selectedOptions)
                  }
                  options={client.dropdownOptions}
                  isDisabled={!isAdmin}
                />
              </FormGroup>
            </Form>
          );
        },
      },
      {
        text: "Timestamp",
        dataField: "updatedAt",
        sort: true,
        formatter: cell => moment(cell).format("MM-DD-YYYY HH:mm:ss"),
      },
      {
        text: "External reference",
        dataField: "externalReference",
        formatter: (cellContent, row) => {
          const idClient = row?._id || row?.id || clientId;
          return (
            <RefComponent
              client={row}
              ref={ref => {
                if (!this.refComponents) this.refComponents = new Map();
                this.refComponents.set(idClient, ref);
              }}
              onSubmitSuccess={() => {
                // Handle success if needed
                this.setState(prevState => ({
                  referenceUpdates: new Set(
                    [...prevState.referenceUpdates].filter(
                      id => id !== idClient
                    )
                  ),
                }));
                this.fetchClients()
                toast.success("External reference updated successfully");
              }}
              onSubmitError={error => {
                // Handle error if needed
                console.error("Error updating reference:", error);
                toast.error("Failed to update reference");
              }}
              onChange={(values) => this.handleReferenceChange(idClient, values)}
              fetchClients={() => this.fetchClients}
            />
          );
        },
      },
      {
        text: "Action",
        dataField: "Action",
        isDummyField: true,
        formatter: (cellContent, client) => {
          const idClient = client?._id || client?.id;
          const hasChanges =
          updatedClients.has(idClient) || referenceUpdates.has(idClient);
          return (
            <Button
              onClick={() => this.handleSaveAndNext(idClient || clientId)}
              disabled={!isAdmin || !hasChanges}
              className="common-button-color"
            >
              Save
            </Button>
          );
        },
      },
    ];
  };

  render() {
    const {
      clients,
      totalSize,
      size,
      page,
      searchTerm,
      updatedClients,
      referenceUpdates,
    } = this.state;
    const { clientId } = this.props;
    const { userData } = this.context;
    const isAdmin =
      userData.role === userRoles.ADMIN ||
      userData.role === userRoles.DEPARTMENT_MANAGER;

    const defaultSorted = [
      {
        dataField: "updatedAt",
        order: "desc",
      },
    ];

    const columns = this.getColumns();

    return (
      <React.Fragment>
        <div className={`${!clientId && "page-content"}`}>
          <Container fluid={true}>
            {!clientId && (
              <Breadcrumbs
                title="Referral List"
                breadcrumbItem="Referral List"
              />
            )}
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {clients.length && !clientId ? (
                      <Col sm="3">
                        <div className="app-search d-none d-lg-block">
                          <div className="position-relative">
                            <FormControl
                              className="search-form-control"
                              placeholder="Search"
                              aria-label="Search"
                              aria-describedby="basic-addon1"
                              value={searchTerm}
                              onChange={this.searchClients}
                              onKeyDown={e => {
                                if (e.key === "Enter") {
                                  this.searchClients(e);
                                }
                              }}
                            />
                            <span className="search-icon bx bx-search-alt" />
                          </div>
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                    <div className="d-flex justify-content-center">
                      <img
                        src={emptyContacts}
                        alt="empty-contact"
                        className={`${
                          clients.length === 0 ? "" : "display-none"
                        }`}
                      />
                    </div>
                    <div
                      className={`${
                        !clientId && "table-responsive"
                      } table-striped ${
                        clients.length === 0 ? "display-none" : ""
                      }`}
                    >
                      <BootstrapTable
                        keyField="_id"
                        data={clients}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        bordered={false}
                        striped={false}
                        responsive
                      />
                      {!clientId && (
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={page - 1}
                            currentSize={size}
                          />
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ReferralDepartment.propTypes = {
  showPageLoader: PropTypes.func.isRequired,
  clientId: PropTypes.string,
};

ReferralDepartment.defaultProps = {
  clientId: "",
};

const mapDispatchToProps = dispatch => ({
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(null, mapDispatchToProps)(ReferralDepartment);
