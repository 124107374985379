import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";
import {
  capitalizeFirstLetter,
  examiners,
  formKey,
  medicalStatusOptions,
  settlementSubFormKey,
} from "constants/constants";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import CustomDatePicker from "components/Common/DateField";

const validationSchema = Yup.object().shape({
  statusSelect: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    })
    .nullable()
    .required("Status is required"),
  examinerSelect: Yup.object()
    .nullable()
    .when("statusSelect.value", {
      is: "scheduled",
      then: Yup.object().nullable().required("Examiner is required"),
      otherwise: Yup.object().nullable(),
    }),
  appointmentDate: Yup.date()
    .nullable()
    .when("statusSelect.value", {
      is: "scheduled",
      then: Yup.date().required(
        "Appointment date is required when status is scheduled"
      ),
      otherwise: Yup.date().optional(),
    }),
});

class Medical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        statusSelect: "",
        examinerSelect: "",
        appointmentDate: "",
        referrenceNote: "",
      },
    };
  }
  componentDidMount = () => {
    const { clientData } = this.props;
    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.SETTLEMENT &&
          note.subForm === settlementSubFormKey.IMMIGRATION_MEDICAL &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const examinerData = clientData?.settlement?.immigrationMedical?.examiners;
    const examinerSelect = examinerData
      ? examiners.find(examiner => examiner.label === examinerData.name) || ""
      : "";
    const appointmentDate =
      clientData?.settlement?.immigrationMedical?.appointmentDate;
    this.setState({
      initialValues: {
        statusSelect: clientData?.settlement?.immigrationMedical?.status
          ? {
              label: capitalizeFirstLetter(
                clientData?.settlement?.immigrationMedical?.status
              ),
              value: clientData?.settlement?.immigrationMedical?.status,
            }
          : "",
        examinerSelect: examinerSelect,
        appointmentDate: appointmentDate
          ? new Date(appointmentDate).toISOString().split("T")[0]
          : "" || "",
        referrenceNote: refNote || "",
      },
    });
  };

  handleAddSettlementMedicalInfo = async data => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/settlement/immigrationMedical/${clientData?.id}`,
        data
      );
      if (response?.status) {
        toast.success(
          response?.message || "IRCC information stored successfully!"
        );
        handleGetClient();
        toggle("5");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error handleAddSettlementMedicalInfo: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      immigrationMedicalInfo: {
        status: values?.statusSelect?.value,
        ...(values?.statusSelect?.value === "scheduled" && {
          examiners: {
            name: values.examinerSelect.label,
            address: values.examinerSelect.address,
            phoneNumber: values.examinerSelect.phoneNumber,
            languages: values.examinerSelect.languages,
          },
          appointmentDate: values.appointmentDate,
        }),
      },
      referenceNotes: values?.referrenceNote,
    };
    this.handleAddSettlementMedicalInfo(data);
  };

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              this.handleSubmit(values);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Medical Information</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-4 mb-3">
                            <Label for="statusSelect" className="form-label">
                              Status
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              id="statusSelect"
                              name="statusSelect"
                              value={values.statusSelect}
                              options={medicalStatusOptions}
                              onChange={selectedOption => {
                                setFieldValue("statusSelect", selectedOption);
                                if (selectedOption?.value !== "scheduled") {
                                  setFieldValue("examinerSelect", "");
                                  setFieldValue("appointmentDate", "");
                                }
                              }}
                            />
                            <ErrorMessage
                              name="statusSelect"
                              component="div"
                              className={
                                values?.statusSelect
                                  ? "invalid-feedback"
                                  : "text-danger"
                              }
                            />
                          </div>
                          {values?.statusSelect?.value === "scheduled" ? (
                            <div className="d-flex">
                              <div className="col-md-4 me-3">
                                <Label
                                  for="examinerSelect"
                                  className="form-label"
                                >
                                  Select examiner
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  id="examinerSelect"
                                  name="examinerSelect"
                                  value={values?.examinerSelect}
                                  options={examiners}
                                  onChange={selectedOption => {
                                    setFieldValue(
                                      "examinerSelect",
                                      selectedOption
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="examinerSelect"
                                  component="div"
                                  className={
                                    values?.examinerSelect
                                      ? "invalid-feedback"
                                      : "text-danger"
                                  }
                                />
                              </div>
                              <div className="col-md-4">
                                <Label
                                  for="appointmentDate"
                                  className="form-label"
                                >
                                  Appointment Date
                                  <span className="text-danger">*</span>
                                </Label>
                                {/* <Field
                                  id="appointmentDate"
                                  name="appointmentDate"
                                  type="date"
                                  className={
                                    "form-control" +
                                    (errors.appointmentDate &&
                                    touched.appointmentDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                /> */}
                                <CustomDatePicker
                                  className={
                                    "form-control" +
                                    (errors?.appointmentDate &&
                                    touched?.appointmentDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                  selected={
                                    values?.appointmentDate
                                      ? values.appointmentDate
                                      : null
                                  }
                                  onChange={date => {
                                    if (date) {
                                      setFieldValue("appointmentDate", date);
                                    } else {
                                      setFieldValue("appointmentDate", null);
                                    }
                                  }}
                                  placeholderText="YYYY-MM-DD"
                                />
                                <ErrorMessage
                                  name="appointmentDate"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          ) : null}
                          {values.examinerSelect && (
                            <div className="mt-3">
                              <h5>Examiner Details</h5>
                              <p>
                                <strong>Name:</strong>{" "}
                                {values?.examinerSelect?.label}
                              </p>
                              <p>
                                <strong>Address:</strong>{" "}
                                {values?.examinerSelect?.address}
                              </p>
                              <p>
                                <strong>Phone Number:</strong>{" "}
                                {values?.examinerSelect?.phoneNumber}
                              </p>
                              <p>
                                <strong>Languages:</strong>{" "}
                                {values?.examinerSelect?.languages?.join(", ")}
                              </p>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label for="referrenceNote" className="form-label">
                              Referrence Note
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="referrenceNote"
                              name="referrenceNote"
                              as="textarea"
                              className={
                                "form-control" +
                                (errors?.referrenceNote &&
                                touched?.referrenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              rows={5}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="referrenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn common-button-color" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Medical));
