import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Breadcrumb from "../../components/Common/Breadcrumb";
import { editProfile, resetProfileFlag } from "../../store/actions";
import { UserContext } from "components/UserProvider/UserProvider";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import { setShowPageLoader } from "store/actions";
import { genderOptions } from "constants/constants";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your name"),
  gender: Yup.string().required("Please select your gender"),
  phoneNumber: Yup.string().required("Please enter your phone number"),
});

class UserProfile extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
  }

  updateProfile = async profileData => {
    try {
      setShowPageLoader(true);
      const { userData } = this.context;
      const response = await axiosPatch(`users/${userData?.id}`, profileData);
      if (response?.status) {
        toast.success("Profile updated successfully!");
      } else {
        toast.error(response?.message || "Oops! something went wrong");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error while updating profile :", error);
    }
  };

  handleSubmit = values => {
    const data = {
      name: values["name"],
      phoneNumber: `${values["phoneNumber"]}`,
      gender: values["gender"],
    };
    this.updateProfile(data);
  };

  render() {
    const { userData } = this.context;

    const initialValues = {
      name: userData?.name,
      email: userData?.email,
      gender: userData?.gender,
      phoneNumber: userData?.phoneNumber,
      role: userData?.role,
      departments: userData?.departments
        ?.map(department => department?.name)
        .join(", "),
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Profile" breadcrumbItem="Profile update" />
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, actions) => {
                    this.handleSubmit(values);
                    actions.setSubmitting(false);
                  }}
                >
                  {({ errors, status, touched, setFieldValue }) => (
                    <Form>
                      <div className="personal-info">
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md="12">
                                <Row>
                                  <Col md="6" className="mb-3">
                                    <Label for="name" className="form-label">
                                      Name{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Field
                                      id="name"
                                      name="name"
                                      type="text"
                                      className={`form-control${
                                        errors.name && touched.name
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="name"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col md="6" className="mb-3">
                                    <Label for="email" className="form-label">
                                      Email
                                    </Label>
                                    <Field
                                      id="email"
                                      name="email"
                                      type="email"
                                      disabled
                                      className={`form-control${
                                        errors.email && touched.email
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="email"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col md="6" className="mb-3">
                                    <Label for="gender" className="form-label">
                                      Gender{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <div className="mt-2">
                                      {Object.keys(genderOptions).map(
                                        (key, index) => {
                                          return (
                                            <Fragment key={`${key}-${index}`}>
                                              <Field
                                                name="gender"
                                                type="radio"
                                                value={genderOptions[key]}
                                                id={genderOptions[key]}
                                                className="form-check-input me-1"
                                              />
                                              <Label
                                                className="form-check-label me-5"
                                                htmlFor={genderOptions[key]}
                                              >
                                                {key}
                                              </Label>
                                            </Fragment>
                                          );
                                        }
                                      )}
                                    </div>
                                    <ErrorMessage
                                      name="gender"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col md="6" className="mb-3">
                                    <Label
                                      for="phoneNumber"
                                      className="form-label"
                                    >
                                      Phone Number{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Field
                                      id="phoneNumber"
                                      name="phoneNumber"
                                      type="text"
                                      className={`form-control${
                                        errors.phoneNumber &&
                                        touched.phoneNumber
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="phoneNumber"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col md="6" className="mb-3">
                                    <Label for="role" className="form-label">
                                      Role
                                    </Label>
                                    <Field
                                      id="role"
                                      name="role"
                                      type="text"
                                      disabled
                                      className={`form-control${
                                        errors.role && touched.role
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="role"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col md="6" className="mb-3">
                                    <Label for="role" className="form-label">
                                      Departments
                                    </Label>
                                    <Field
                                      id="departments"
                                      name="departments"
                                      type="text"
                                      disabled
                                      className={`form-control${
                                        errors.departments &&
                                        touched.departments
                                          ? " is-invalid"
                                          : ""
                                      }`}
                                    />
                                    <ErrorMessage
                                      name="departments"
                                      component="departments"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button className="btn btn-primary" type="submit">
                          Update Profile
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStateToProps, { editProfile, resetProfileFlag })(UserProfile)
);
