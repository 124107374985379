import React, { Component } from "react";

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
//import './App.scss';
import {
  screeningFormStatus,
  userRoles,
  users,
  PAGE,
  LIMIT,
  SIZE_PER_PAGE,
  TOTAL_SIZE,
  applicationStatus,
  capitalizeFirstLetter,
} from "constants/constants";
import BootstrapTable from "react-bootstrap-table-next";
import { InputGroup, FormControl } from "react-bootstrap";
import { axiosGet, axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import emptyContacts from "../../../assets/images/emptyContact.png";
import Breadcrumbs from "components/Common/Breadcrumb";
import { UserContext } from "components/UserProvider/UserProvider";
import Pagination from "components/pagination/Pagination";
import Limit from "components/pagination/Limit";
import DeleteModal from "components/Common/DeleteModal";
import PropTypes from "prop-types";
import { setShowPageLoader, updateUnreadCount } from "store/actions";
import { connect } from "react-redux";
import moment from "moment";
import RefComponent from "components/Common/ReferenceComponent";
class ApprovedDashboard extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      clients: [],
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      page: PAGE,
      searchTerm: "",
      deleteModal: false,
      selectedClient: null,
      applicationStatus: "",
      usersListColumns: [
        {
          text: "UCI",
          dataField: "screeningForm.generalInformation.uci",
          sort: true,
        },
        {
          text: "Name",
          dataField: "screeningForm.generalInformation.firstName",
          sort: true,
          formatter: (cellContent, client) => {
            const middleName =
              client?.screeningForm?.generalInformation?.middleName;
            const lastName =
              client?.screeningForm?.generalInformation?.lastName;
            return (
              <>
                <p className="text-muted mb-0">{`${capitalizeFirstLetter(
                  cellContent
                )} ${capitalizeFirstLetter(middleName)} ${capitalizeFirstLetter(
                  lastName
                )}`}</p>
              </>
            );
          },
        },
        {
          text: "Email",
          dataField: "screeningForm.generalInformation.email",
          sort: true,
        },
        {
          text: "Phone Number",
          dataField: "screeningForm.generalInformation.phoneNumber",
          sort: true,
        },
        {
          text: "Date of Birth",
          dataField: "screeningForm.generalInformation.dateOfBirth",
          sort: true,
          formatter: cell => moment(cell).format("MM-DD-YYYY"),
        },
        {
          text: "status",
          dataField: "screeningApprovalStatus",
          sort: true,
          formatter: (cellContent, row) => {
            return (
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-check-circle fs-5 text-success"
                  style={{ marginRight: "5px" }}
                />
                {capitalizeFirstLetter(row.screeningApprovalStatus)}
              </div>
            );
          },
        },
        {
          text: "Reference",
          dataField: "reference",
          isDummyField: true,
          formatter: (cellContent, row) => (
            <RefComponent
              client={row}
              ref={(ref) => {
                // Store ref in a map using row._id as key
                if (!this.refComponents) this.refComponents = new Map();
                this.refComponents.set(row._id, ref);
              }}
              onSubmitSuccess={() => {
                // Handle success if needed
                console.log("Reference updated successfully");
                toast.success("Reference updated successfully");

              }}
              onSubmitError={(error) => {
                // Handle error if needed
                console.error("Error updating reference:", error);
                toast.error("Failed to update reference");
              }}
            />
          )
        },
        {
          dataField: "Action",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, row) => (
            <div className="d-flex gap-3">
              {(
                <>
                  {/* <i
                    className="bx bx-check font-size-20 text-success me-2"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Approve"
                    onClick={() => {
                      this.setState({
                        status: applicationStatus.APPROVED,
                        selectedClient: row,
                        deleteModal: true,
                      });
                    }}
                  ></i> */}

                  <i
                    className="bx bx-x font-size-20 text-danger me-2"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Decline"
                    onClick={() => {
                      this.setState({
                        status: applicationStatus.DECLINED,
                        selectedClient: row,
                        deleteModal: true,
                      });
                    }}
                  ></i>
                </>
              )}
              <Link className="text-success" to={`/add-clients/${row._id}`}>
                <i
                  className="bx bx-edit font-size-20 text-primary"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit"
                  onClick={() => {
                    // Handle edit action
                  }}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.refComponents = new Map();
    this.timer = null;
  }

  fetchUnreadNotifications = async () => {
    try {
      // Make API call to fetch unread notifications
      const response = await axiosGet("/notification/unread-notifications");
      if (response.status) {
        const unreadCount = response.data.totalResults;
        this.props.updateUnreadCount(unreadCount);
        // Update unread count in Redux store
      } else {
        console.error("Failed to fetch unread notifications");
      }
    } catch (error) {
      console.error("Error fetching unread notifications:", error);
    }
  };

  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.handleGetAllClients();
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.handleGetAllClients();
    });
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ clients: [] }, function () {
        this.handleGetAllClients();
      });
    }, WAITING_TIMING);
  };

  searchUsers = event => {
    const { value } = event.target;
    this.setState(
      {
        searchTerm: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  handleDeleteClient = async () => {
    const { status, selectedClient } = this.state;
    const clientId = selectedClient._id;
    const { showPageLoader } = this.props;
    try {
      showPageLoader(true);
      const refComponent = this.refComponents.get(clientId);
      if (refComponent) {
        // Trigger the form submission in RefComponent
        await refComponent.submitForm();
      }
      if (status === applicationStatus.DECLINED) {
        const response = await axiosPatch(`/add-client/${clientId}/decline`);
        if (response.status) {
          toast.success("Client's screening status has been declined.");
          this.handleGetAllClients();
          this.fetchUnreadNotifications();
        }
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      toast.error("Error at handleDeclineClient:", error);
    }
    this.setState({ deleteModal: false });
  };

  handleGetAllClients = async () => {
    const { size, page, searchTerm } = this.state;
    const { showPageLoader } = this.props;
    try {
      showPageLoader(true);
      const { userData } = this.context;

      const response = await axiosGet(
        `/add-client/screening?page=${page}&limit=${size}&searchString=${searchTerm}&screeningApprovalStatus=${
          userData.role === userRoles.ADMIN
            ? screeningFormStatus.APPROVED
            : userData.role === userRoles.STAFF
            ? `${screeningFormStatus.APPROVED}`
            : `${screeningFormStatus.APPROVED}`
        }`
      );
      if (response.status) {
        this.setState({
          clients: response?.data?.results,
          totalSize: response?.data?.totalResults,
        });
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      console.error("Error at handleGetAllClients:", error);
    }
  };

  componentDidMount = () => {
    this.handleGetAllClients();
  };

  render() {
    const { clients } = this.state;
    const defaultSorted = [
      {
        dataField: "createdAt", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];
    return (
      <React.Fragment>
        <DeleteModal
          show={this.state.deleteModal}
          client={this.state.selectedClient}
          onDeleteClick={this.handleDeleteClient}
          onCloseClick={() => this.setState({ deleteModal: false })}
          buttonText={
            this.state.status === applicationStatus.DECLINED
              ? "Yes, Decline it!"
              : "Yes, Approve it!"
          }
        />
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Clients" breadcrumbItem="Client List" />
            <div className="super-admin">
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      {clients.length ? (
                        <Col sm="3">
                          <div className="app-search d-none d-lg-block">
                            <div className="position-relative">
                              <FormControl
                                className="search-form-control"
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                                value={this.state.searchTerm}
                                onChange={e => {
                                  this.searchUsers(e);
                                }}
                                onKeyDown={e => {
                                  if (e.key === "Enter") {
                                    this.searchUsers(e);
                                  }
                                }}
                              />
                              <span className="search-icon bx bx-search-alt" />
                            </div>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                      <div className="d-flex justify-content-center">
                        <img
                          src={emptyContacts}
                          alt="empty-conatct"
                          className={`${
                            clients.length === 0 ? "" : "display-none"
                          }`}
                        ></img>
                      </div>
                      <div
                        className={`table-responsive table-striped ${
                          clients.length === 0 ? "display-none" : ""
                        }`}
                      >
                        <BootstrapTable
                          keyField="_id"
                          data={clients}
                          columns={this.state.usersListColumns}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          bordered={false}
                          striped={false}
                          responsive
                          ref={this.node}
                        />
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <Limit
                            value={this.state.size}
                            onChange={this.handleLimitChange}
                          />
                          <Pagination
                            totalSize={this.state.totalSize}
                            handlePageClick={this.handlePageClick}
                            currentPage={this.state.page - 1} // Adjust for 0-based indexing in React Paginate
                            currentSize={this.state.size} // Use selectedLimit for current size
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
ApprovedDashboard.propTypes = {
  updateUnreadCount: PropTypes.func.isRequired,
  unreadCount: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  unreadCount: state.Notifications.unreadCount || 0,
});

const mapDispatchToProps = dispatch => ({
  updateUnreadCount: count => dispatch(updateUnreadCount(count)),
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovedDashboard);
