import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { setShowPageLoader } from "store/actions";
import {
  capitalizeFirstLetter,
  formKey,
  settlementSubFormKey,
} from "constants/constants";
import { toast } from "react-toastify";
import { axiosPatch } from "helpers/api_helpers";
import CustomDatePicker from "components/Common/DateField";

const hearingStatusOptions = [
  { label: "Scheduled", value: "scheduled" },
  { label: "Started", value: "started" },
  { label: "In Progress", value: "in_progress" },
  { label: "Successful", value: "successful" },
  { label: "Unsuccessful", value: "unsuccessful" },
];

const hearingOptions = {
  STARTED: "started",
  IN_PROGRESS: "in_progress",
  SCHEDULED: "scheduled",
  SUCCESSFUL: "successful",
  UNSUCCESSFUL: "unsuccessful",
};

// Validation schema
const validationSchema = Yup.object().shape({
  hearingStatus: Yup.array()
    .of(
      Yup.object().shape({
        statusOption: Yup.object()
          .nullable()
          .required("Status option is required"),
        nextHearingDate: Yup.string().required("Next hearing date is required"),
        rejectionReason: Yup.string().when("statusOption.value", {
          is: "unsuccessful",
          then: Yup.string().required("Rejection reason is required"),
          otherwise: Yup.string().nullable(),
        }),
        notes: Yup.string().when("statusOption.value", {
          is: "unsuccessful",
          then: Yup.string().required("Notes are required"),
          otherwise: Yup.string().nullable(),
        }),
      })
    )
    .required("At least one hearing status is required"),
});

// Initial values
const initialValues = {
  referrenceNote: "",
  hearingStatus: [
    {
      statusOption: null,
      nextHearingDate: "",
      rejectionReason: "",
      notes: "",
    },
  ],
};

class Hearing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: initialValues,
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;
    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.SETTLEMENT &&
          note.subForm === settlementSubFormKey.HEARING_STATUS &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    if (clientData?.settlement?.hearingStatus?.hearing?.length) {
      const hearingStatus = clientData?.settlement?.hearingStatus?.hearing?.map(
        hearing => {
          return {
            statusOption: {
              label: capitalizeFirstLetter(hearing?.status)
                ?.split("_")
                ?.join(" "),
              value: hearing?.status,
            },
            nextHearingDate: new Date(hearing?.nextHearingDate)
              .toISOString()
              .split("T")[0],
            rejectionReason: hearing?.rejectionReason,
            notes: hearing?.statusNotes,
          };
        }
      );
      this.setState({
        initialValues: {
          hearingStatus,
          referrenceNote: refNote || "",
        },
      });
    }
  };

  handleAddSettlementHearingInfo = async data => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/settlement/hearingStatus/${clientData?.id}`,
        data
      );
      if (response?.status) {
        toast.success(
          response?.message || "IRCC information stored successfully!"
        );
        handleGetClient();
        // toggle("7");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error handleAddSettlementHearingInfo: ", error);
    }
  };

  handleSubmit = async values => {
    const hearingArray = values?.hearingStatus?.map(hearing => {
      const transformedStatus = {
        status: hearing?.statusOption?.value,
        nextHearingDate: hearing?.nextHearingDate,
      };

      if (hearing?.rejectionReason) {
        transformedStatus.rejectionReason = hearing.rejectionReason;
      }

      if (hearing?.notes) {
        transformedStatus.statusNotes = hearing.notes;
      }

      return transformedStatus;
    });
    const data = {
      hearingStatusInfo: {
        hearing: hearingArray,
      },
      referenceNotes: values?.referrenceNote,
    };
    this.handleAddSettlementHearingInfo(data);
  };

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              this.handleSubmit(values);
            }}
          >
            {({
              errors,
              status,
              touched,
              values,
              setFieldValue,
              setFieldTouched,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Hearing Status</h4>
                        </Col>
                        <Col md="9">
                          <FieldArray name="hearingStatus">
                            {({ insert, remove, push }) => (
                              <div>
                                {values.hearingStatus.length > 0 &&
                                  values.hearingStatus.map((hearing, index) => (
                                    <div key={index} className="d-flex">
                                      <div className="col-md-4 mb-3 me-3">
                                        <Label
                                          for={`statusOptionLabel${index}`}
                                          className="form-label"
                                        >
                                          Status
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Select
                                          id={`statusOptionLabel${index}`}
                                          name={`hearingStatus.${index}.statusOption`}
                                          value={
                                            values.hearingStatus[index]
                                              .statusOption
                                          }
                                          options={hearingStatusOptions}
                                          className={`${
                                            errors.hearingStatus?.[index]
                                              ?.statusOption &&
                                            touched.hearingStatus?.[index]
                                              ?.statusOption
                                              ? " is-invalid"
                                              : ""
                                          }`}
                                          onChange={selectedOption => {
                                            setFieldValue(
                                              `hearingStatus.${index}.statusOption`,
                                              selectedOption
                                            );
                                            setFieldTouched(
                                              `hearingStatus.${index}.statusOption`,
                                              true
                                            );
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`hearingStatus.${index}.statusOption`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>

                                      <Col md="3 me-2">
                                        <label
                                          htmlFor={`nextHearingDate${index}`}
                                          className="form-label"
                                        >
                                          Next Hearing Date{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        {/* <Field
                                          id={`nextHearingDate${index}`}
                                          name={`hearingStatus.${index}.nextHearingDate`}
                                          type="date"
                                          className={`form-control${
                                            errors.hearingStatus?.[index]
                                              ?.nextHearingDate &&
                                            touched.hearingStatus?.[index]
                                              ?.nextHearingDate
                                              ? " is-invalid"
                                              : ""
                                          }`}
                                        /> */}
                                        <CustomDatePicker
                                          className={
                                            "form-control" +
                                            (errors?.hearingStatus?.[index]
                                              ?.nextHearingDate &&
                                            touched?.hearingStatus?.[index]
                                              ?.nextHearingDate
                                              ? " is-invalid"
                                              : "")
                                          }
                                          selected={
                                            values?.hearingStatus?.[index]
                                              ?.nextHearingDate
                                              ? values.hearingStatus?.[index]
                                                  ?.nextHearingDate
                                              : null
                                          }
                                          onChange={date => {
                                            if (date) {
                                              setFieldValue(
                                                `hearingStatus.${index}.nextHearingDate`,
                                                date
                                              );
                                            } else {
                                              setFieldValue(
                                                `hearingStatus.${index}.nextHearingDate`,
                                                null
                                              );
                                            }
                                          }}
                                          placeholderText="YYYY-MM-DD"
                                        />
                                        <ErrorMessage
                                          name={`hearingStatus.${index}.nextHearingDate`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </Col>
                                      <div className="d-flex align-items-center mt-2">
                                        <button
                                          type="button"
                                          className="btn btn-danger p-1"
                                          onClick={() => remove(index)}
                                        >
                                          <i className="fs-5 bx bx-trash-alt"></i>
                                        </button>
                                      </div>
                                      {hearing?.statusOption?.value ===
                                        "unsuccessful" && (
                                        <div className="d-flex">
                                          <Col md="3 me-3">
                                            <label
                                              htmlFor={`rejectionReason${index}`}
                                              className="form-label"
                                            >
                                              Rejection Reason{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              id={`rejectionReason${index}`}
                                              name={`hearingStatus.${index}.rejectionReason`}
                                              type="text"
                                              className={`form-control${
                                                errors.hearingStatus?.[index]
                                                  ?.rejectionReason &&
                                                touched.hearingStatus?.[index]
                                                  ?.rejectionReason
                                                  ? " is-invalid"
                                                  : ""
                                              }`}
                                            />
                                            <ErrorMessage
                                              name={`hearingStatus.${index}.rejectionReason`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                          <Col md="3">
                                            <label
                                              htmlFor={`notes${index}`}
                                              className="form-label"
                                            >
                                              Notes{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <Field
                                              id={`notes${index}`}
                                              name={`hearingStatus.${index}.notes`}
                                              type="text"
                                              className={`form-control${
                                                errors.hearingStatus?.[index]
                                                  ?.notes &&
                                                touched.hearingStatus?.[index]
                                                  ?.notes
                                                  ? " is-invalid"
                                                  : ""
                                              }`}
                                            />
                                            <ErrorMessage
                                              name={`hearingStatus.${index}.notes`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </Col>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                <Button
                                  type="button"
                                  className="d-flex justiify-content-center align-items-center mb-3 common-button-color"
                                  onClick={() => {
                                    push({
                                      statusOption: null,
                                      nextHearingDate: "",
                                      rejectionReason: "",
                                      notes: "",
                                    });
                                    values.hearingStatus.forEach((_, idx) => {
                                      setFieldTouched(
                                        `hearingStatus.${idx}.statusOption`,
                                        true
                                      );
                                    });
                                  }}
                                >
                                  <i className="bx bx-plus fs-5 me-1"></i>
                                  <span>Add Hearing Status</span>
                                </Button>
                              </div>
                            )}
                          </FieldArray>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label for="referrenceNote" className="form-label">
                              Reference Note
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="referrenceNote"
                              name="referrenceNote"
                              as="textarea"
                              className={
                                "form-control" +
                                (errors?.referrenceNote &&
                                touched?.referrenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              rows={5}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="referrenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn common-button-color" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Hearing));
